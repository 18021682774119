import React, { ChangeEvent, useState, FormEvent } from 'react';
import { useAppState } from '../../state';

import Button from '@material-ui/core/Button';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Grid from '@material-ui/core/Grid';
import { ReactComponent as GoogleLogo } from './google-logo.svg';
import { InputLabel, Theme } from '@material-ui/core';
import IntroContainer from '../IntroContainer/IntroContainer';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '../../icons/InfoIconOutlined';
//import AboutDialog from '../AboutDialog/AboutDialog';
import Dialog1 from '../Dialogs/Dialog1/Dialog1';
import Dialog2 from '../Dialogs/Dialog2/Dialog2';
import Dialog3 from '../Dialogs/Dialog3/Dialog3';
//import Dialog4 from '../Dialogs/Dialog4/Dialog4';
import Dialog5 from '../Dialogs/Dialog5/Dialog5';
import Dialog6 from '../Dialogs/Dialog6/Dialog6';
import Dialog8 from '../Dialogs/Dialog8/Dialog8';

import MenuItem from '@material-ui/core/MenuItem';

import { makeStyles } from '@material-ui/core/styles';
import { useLocation, useHistory } from 'react-router-dom';

import { Magic } from 'magic-sdk';
const magic = new Magic('pk_live_474AF3654EECAD79');

const useStyles = makeStyles((theme: Theme) => ({
  googleButton: {
    background: 'white',
    color: 'rgb(0, 94, 166)',
    borderRadius: '4px',
    border: '2px solid rgb(2, 122, 197)',
    margin: '1.8em 0 0.7em',
    textTransform: 'none',
    boxShadow: 'none',
    padding: '0.3em 1em',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '&:hover': {
      background: 'white',
      boxShadow: 'none',
    },
  },
  errorMessage: {
    color: 'red',
    display: 'flex',
    alignItems: 'center',
    margin: '1em 0 0.2em',
    '& svg': {
      marginRight: '0.4em',
    },
  },
  gutterBottom: {
    marginBottom: '1em',
  },
  item1: {
    marginBottom: '1em',
    marginRight: '4em',
  },
  item: {
    width: '20em',
    marginBottom: '1em',
  },
  passcodeContainer: {
    minHeight: '120px',
  },
  submitButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

export default function LoginPage() {
  const classes = useStyles();
  const { signIn, user, isAuthReady } = useAppState();
  const history = useHistory();
  const location = useLocation<{ from: Location }>();
  const [passcode, setPasscode] = useState('');
  const [email, setEmail] = useState('');
  const [authError, setAuthError] = useState<Error | null>(null);
  const [authEmail, setAuthEmail] = useState<String | null>(null);
  var [loggedInWithMagic, setLoggedInWithMagic] = useState(false);
  const isAuthEnabled = Boolean(process.env.REACT_APP_SET_AUTH);

  //const [aboutOpen, setAboutOpen] = useState(false);
  const [dialog1Open, setDialog1Open] = useState(false);
  const [dialog2Open, setDialog2Open] = useState(false);
  const [dialog3Open, setDialog3Open] = useState(false);
  const [dialog4Open, setDialog4Open] = useState(false);
  const [dialog5Open, setDialog5Open] = useState(false);
  const [dialog6Open, setDialog6Open] = useState(false);

  const isMobile = window.matchMedia('only screen and (max-width: 760px)').matches;

  const login = () => {
    setAuthError(null);
    signIn?.(passcode)
      .then(() => {
        history.replace(location?.state?.from || { pathname: '/' });
      })
      .catch(err => setAuthError(err));
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    login();
  };

  const handleLogOut = async () => {
    // THIS CAN BE REMOVED...
    await magic.user.logout();
    setLoggedInWithMagic(false);
    setAuthEmail(null);
  };

  const handleLogin = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setAuthError(null);
    //const redirectURI = `${window.location.origin}/callback`;
    if (email) {
      /* One-liner login 🤯 */
      console.log('sending to: ' + email);
      await magic.auth
        .loginWithMagicLink({ email })
        .then(() => {
          signIn?.(passcode) // THIS IS A DIRTY HACK FOR NOW (COPYED FROM PASSWORD LOGIN METHOD) NEEDS TO BE FIXED.
            .then(() => {
              console.log('Sucessfully signed in??');
              history.replace(location?.state?.from || { pathname: '/' });
            })
            .catch(err => setAuthError(err));
        })
        .catch(err => setAuthError(err));
    }
  };

  if (user || !isAuthEnabled) {
    history.replace('/');
  }

  if (!isAuthReady) {
    return null;
  }

  if (isMobile) {
    return (
      <IntroContainer>
        <>
          <Typography variant="h5" className={classes.gutterBottom}>
            Denne testen må du ta på en PC.
          </Typography>
          <Typography variant="body1">
            Testen som du nå prøver å ta er bestilt for å tas på en PC og kan ikke tas fra en mobiltelefon. Gå til en
            datamaskin og åpne linken i e-posten der i stedet for.
          </Typography>
        </>
      </IntroContainer>
    );
  } else {
    return (
      <IntroContainer>
        {process.env.REACT_APP_SET_AUTH === 'firebase' && (
          <>
            <Typography variant="h5" className={classes.gutterBottom}>
              Sign in to join a room
            </Typography>
            <Typography variant="body1">Sign in using your Twilio Google Account</Typography>
            <Button variant="contained" className={classes.googleButton} onClick={login} startIcon={<GoogleLogo />}>
              Sign in with Google
            </Button>
          </>
        )}

        {process.env.REACT_APP_SET_AUTH === 'magic' && loggedInWithMagic && (
          <>
            {authEmail && (
              <Typography variant="h5" className={classes.gutterBottom}>
                Du er logget inn som {JSON.stringify(authEmail)}
              </Typography>
            )}
            <Button onClick={handleLogOut} variant="contained" color="primary" type="submit">
              Logout
            </Button>

            <a href="/">Gå til rom</a>
          </>
        )}

        {process.env.REACT_APP_SET_AUTH === 'magic' && !loggedInWithMagic && (
          <>
            <Typography variant="h5" className={classes.gutterBottom}>
              Logg inn ved å taste inn din epost adresse:
            </Typography>
            <form onSubmit={handleLogin}>
              <Grid container justifyContent="space-between">
                <div className={classes.passcodeContainer}>
                  <InputLabel shrink htmlFor="email">
                    Epost
                  </InputLabel>
                  <TextField
                    id="email"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                    type="email"
                    name="email"
                    variant="outlined"
                    size="small"
                  />

                  <div>
                    {authError && (
                      <Typography variant="caption" className={classes.errorMessage}>
                        <ErrorOutlineIcon />
                        {authError.message}
                      </Typography>
                    )}
                  </div>
                </div>
              </Grid>
              <Grid container justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={!email.length}
                  className={classes.submitButton}
                >
                  Logg inn
                </Button>
              </Grid>
            </form>
          </>
        )}

        {process.env.REACT_APP_SET_AUTH === 'excludeForNow' && (
          <>
            <Typography variant="h5" className={classes.gutterBottom}>
              Takk for at du tok denne testen
            </Typography>

            <Typography variant="body1" className={classes.item1}>
              Testen du nettopp gjennomførte vil nå sendes til godkjenning. Om du har registrert deg med
              kontaktinformasjon så vil du få en bekreftelse på gjennomført test via denne mailadressen. Kontakt
              panel@testflow.no om det er spørsmål eller det er noe vi kan hjelpe deg med.
            </Typography>

            <Typography variant="body2" className={classes.item1}>
              Mer informasjon:
            </Typography>
            <Grid container justifyContent="space-between">
              <MenuItem onClick={() => setDialog1Open(true)}>
                <Typography variant="body1" className={classes.item}>
                  <InfoIcon /> Betalingsstatus og historikk
                </Typography>
              </MenuItem>

              <MenuItem onClick={() => setDialog2Open(true)}>
                <Typography variant="body1" className={classes.item}>
                  <InfoIcon /> Få tilgang til å gjøre flere tester
                </Typography>
              </MenuItem>

              <MenuItem onClick={() => setDialog3Open(true)}>
                <Typography variant="body1" className={classes.item}>
                  <InfoIcon /> Samtykke og tilbaketrekke test
                </Typography>
              </MenuItem>

              <MenuItem onClick={() => setDialog4Open(true)}>
                <Typography variant="body1" className={classes.item}>
                  <InfoIcon /> Personvern og lagring av data
                </Typography>
              </MenuItem>

              <MenuItem onClick={() => setDialog5Open(true)}>
                <Typography variant="body1" className={classes.item}>
                  <InfoIcon /> Kontakt oss
                </Typography>
              </MenuItem>

              <MenuItem onClick={() => setDialog6Open(true)}>
                <Typography variant="body1" className={classes.item}>
                  <InfoIcon /> Om Testflow
                </Typography>
              </MenuItem>
            </Grid>

            <Dialog1
              open={dialog1Open}
              onClose={() => {
                setDialog1Open(false);
              }}
            />
            <Dialog2
              open={dialog2Open}
              onClose={() => {
                setDialog2Open(false);
              }}
            />
            <Dialog3
              open={dialog3Open}
              onClose={() => {
                setDialog3Open(false);
              }}
            />
            <Dialog8
              open={dialog4Open}
              onClose={() => {
                setDialog4Open(false);
              }}
            />
            <Dialog5
              open={dialog5Open}
              onClose={() => {
                setDialog5Open(false);
              }}
            />
            <Dialog6
              open={dialog6Open}
              onClose={() => {
                setDialog6Open(false);
              }}
            />
          </>
        )}

        {process.env.REACT_APP_SET_AUTH === 'passcode' && (
          <>
            <Typography variant="h5" className={classes.gutterBottom}>
              Takk for at du bidrar til å forbedre digitale brukeropplevelser
            </Typography>
            <Typography variant="body1" className={classes.item1}>
              Om du er en registrert bruker hos oss, så lanserer vi snart et eget testerdashboard hvor du vil se status
              over nye og fullførte tester. I mellomtiden send oss en mail på panel@testflow.no om du har spørsmål eller
              det er noe du lurer på.
            </Typography>
            <Typography variant="body1" className={classes.item1}>
              Hilsen oss i Testflow
            </Typography>
          </>
        )}

        {process.env.REACT_APP_SET_AUTH === 'THIS IS OLD ENABLING PURE PASSCODE' && (
          <>
            <Typography variant="h5" className={classes.gutterBottom}>
              Tast inn tilgangskode for å gå inn i rom
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container justifyContent="space-between">
                <div className={classes.passcodeContainer}>
                  <InputLabel shrink htmlFor="input-passcode">
                    Kode
                  </InputLabel>
                  <TextField
                    id="input-passcode"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setPasscode(e.target.value)}
                    type="text"
                    variant="outlined"
                    size="medium"
                  />
                  <div>
                    {authError && (
                      <Typography variant="caption" className={classes.errorMessage}>
                        <ErrorOutlineIcon />
                        {authError.message}
                      </Typography>
                    )}
                  </div>
                </div>
              </Grid>
              <Grid container justifyContent="space-between">
                <Typography variant="body1" className={classes.item}>
                  <InfoIcon /> Eget testerdashboard er under utvikling, i mellomtiden send oss en mail på
                  panel@testflow.no
                </Typography>

                <a href="/">
                  <Typography variant="body1" className={classes.item}>
                    <InfoIcon /> Dine gjennomførte tester
                  </Typography>
                </a>
                <a href="/">
                  <Typography variant="body1" className={classes.item}>
                    <InfoIcon /> Betalingsstatus og historikk
                  </Typography>
                </a>
                <a href="/">
                  <Typography variant="body1" className={classes.item}>
                    <InfoIcon /> Få tilgang til å gjøre flere tester
                  </Typography>
                </a>
                <a href="/">
                  <Typography variant="body1" className={classes.item}>
                    <InfoIcon /> Om oss
                  </Typography>
                </a>
                <a href="/">
                  <Typography variant="body1" className={classes.item}>
                    <InfoIcon /> Vilkår for bruk og personvern
                  </Typography>
                </a>
              </Grid>
              <Grid container justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={!passcode.length}
                  className={classes.submitButton}
                >
                  Neste
                </Button>
              </Grid>
            </form>
          </>
        )}
      </IntroContainer>
    );
  }
}
