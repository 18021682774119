import { createTheme } from '@material-ui/core';

export const lightTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#4167B2',
    },
  },
});
