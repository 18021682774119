import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import TaskSteps from '../TaskSteps/TaskSteps';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: 'bold',
    },
    taskWindowContainer: {
      background: theme.brand,
      color: 'white',
      padding: '20px',
      zIndex: 9,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      overflow: 'auto',
      //borderLeft: '1px solid #E4E7E9',
      [theme.breakpoints.down('sm')]: {
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 100,
      },
    },
    item: {
      justifySelf: 'center',
      marginLeft: '40px',
    },
  })
);

export default function TaskWindow() {
  const classes = useStyles();

  return (
    <aside className={classes.taskWindowContainer}>
      <div className={classes.item}>
        <TaskSteps />
      </div>
    </aside>
  );
}
