import { Theme } from '@material-ui/core';
import { lightTheme } from './light';
import norstatTheme from './norstatTheme';
import testflowDarkTheme from './testflowDarkTheme';
import defaultTheme from './defaultTheme';

export function getThemeByName(theme: string): Theme {
  return themeMap[theme];
}

const themeMap: { [key: string]: Theme } = {
  lightTheme,
  norstatTheme,
  defaultTheme,
  testflowDarkTheme,
};
