import React, { FormEvent } from 'react';
import { Typography, makeStyles, Grid, Button, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1.5em 0 3.5em',
    '& div:not(:last-child)': {
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '1.5em 0 2em',
    },
  },
  textFieldContainer: {
    width: '100%',
  },
  logoutLink: {
    cursor: 'pointer',
  },
  continueButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

interface RoomNameScreenProps {
  name: string;
  roomName: string;
  setName: (name: string) => void;
  setRoomName: (roomName: string) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

export default function RoomNameScreen({ name, roomName, handleSubmit }: RoomNameScreenProps) {
  const classes = useStyles();

  if (!roomName) {
    return (
      <>
        <Typography variant="h5" className={classes.gutterBottom}></Typography>

        <Typography variant="body1"></Typography>
      </>
    );
  } else {
    return (
      <>
        <Typography variant="h5" className={classes.gutterBottom}>
          Hei {name}!
        </Typography>

        <Typography variant="body1">
          I neste steg må du tillate bruk av webkamera og mikrofon for å fortsette.
        </Typography>

        <form onSubmit={handleSubmit}>
          <Grid container justifyContent="flex-end">
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={!name || !roomName}
              className={classes.continueButton}
            >
              Fortsett
            </Button>
          </Grid>
        </form>
      </>
    );
  }
}
