import React, { createContext, useContext, useReducer, useState, useEffect } from 'react';

import { RecordingRules, RoomType, CaseData, User, UserPreferences, Meta } from '../types';
import { TwilioError } from 'twilio-video';
import { settingsReducer, initialSettings, Settings, SettingsAction } from './settings/settingsReducer';
import useActiveSinkId from './useActiveSinkId/useActiveSinkId';
//import useFirebaseAuth from './useFirebaseAuth/useFirebaseAuth';
import usePasscodeAuth from './usePasscodeAuth/usePasscodeAuth';
//import useMagicAuth from './useMagicAuth/useMagicAuth';
//import useCaseDataState from '../hooks/useCaseDataState/useCaseDataState';
import sanityClient from '../sanity';
//import sanityTesterClient from '../sanityTesterClient';
//import useTestCaseData from '../hooks/useTestCaseData/useTestCaseData';

//import { User } from 'firebase';
//import { userInfo } from 'os';

export interface StateContextType {
  error: TwilioError | Error | null;
  setError(error: TwilioError | Error | null): void;
  getToken(name: string, room: string, passcode?: string): Promise<{ room_type: RoomType; token: string }>;
  user?:
    | User
    | null
    | { displayName: undefined; userPreferences: UserPreferences; meta: Meta; photoURL: undefined; passcode?: string };
  signIn?(passcode?: string): Promise<void>;
  signOut?(redirectionUrl?: string): Promise<void>;
  isAuthReady?: boolean;
  isFetching: boolean;
  activeSinkId: string;
  setActiveSinkId(sinkId: string): void;
  settings: Settings;
  dispatchSetting: React.Dispatch<SettingsAction>;
  roomType?: RoomType;
  updateRecordingRules(room_sid: string, rules: RecordingRules): Promise<object>;
  getTesterData(tester_id: string): Promise<object>;
  startTest(room_name: string, room_sid: string, case_id: string): Promise<void>;
  getTestCaseData(slug: string): Promise<object>;
  endTest(room_name: string, room_sid: string, case_id: string, redirectionUrl?: string): Promise<void>;
  caseData?: CaseData;
  redirectionSuccess: string;
  redirectionScreenout: string;
  //testerData?: TesterData;
  //testerId?: TesterId
}

export const StateContext = createContext<StateContextType>(null!);

/*
  The 'react-hooks/rules-of-hooks' linting rules prevent React Hooks from being called
  inside of if() statements. This is because hooks must always be called in the same order
  every time a component is rendered. The 'react-hooks/rules-of-hooks' rule is disabled below
  because the "if (process.env.REACT_APP_SET_AUTH === 'firebase')" statements are evaluated
  at build time (not runtime). If the statement evaluates to false, then the code is not
  included in the bundle that is produced (due to tree-shaking). Thus, in this instance, it
  is ok to call hooks inside if() statements.
*/
export default function AppStateProvider(props: React.PropsWithChildren<{}>) {
  const [error, setError] = useState<TwilioError | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [activeSinkId, setActiveSinkId] = useActiveSinkId();
  const [settings, dispatchSetting] = useReducer(settingsReducer, initialSettings);
  //const [roomType, setRoomType] = useState<RoomType>();
  const [roomType] = useState<RoomType>();
  const [caseData, setCaseData] = useState<CaseData>();

  const [redirectionSuccess, setRedirectionSuccess] = useState('');
  const [redirectionScreenout, setRedirectionScreenout] = useState('');

  //const [testerData, setTesterData] = useState<TesterData>();
  //const [testerId, setTesterId] = useState<TesterId>();

  //const caseDataState = useCaseDataState()

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const redirectionSuccessURL = searchParams.get('redirection_success');
    const redirectionScreenoutURL = searchParams.get('redirection_screenout');

    if (redirectionSuccessURL) {
      setRedirectionSuccess(decodeURIComponent(redirectionSuccessURL));
    }
    if (redirectionScreenoutURL) {
      setRedirectionScreenout(decodeURIComponent(redirectionScreenoutURL));
    }
  }, []);

  let contextValue = {
    error,
    setError,
    isFetching,
    activeSinkId,
    setActiveSinkId,
    settings,
    dispatchSetting,
    roomType,
    caseData,
    //testerData,
    //testerId
    redirectionSuccess,
    redirectionScreenout,
  } as StateContextType;

  //if (process.env.REACT_APP_SET_AUTH === 'firebase') {
  //contextValue = {
  // ...contextValue,
  //...useFirebaseAuth(), // eslint-disable-line react-hooks/rules-of-hooks
  // };
  if (process.env.REACT_APP_SET_AUTH === 'passcode') {
    contextValue = {
      ...contextValue,
      ...usePasscodeAuth(), // eslint-disable-line react-hooks/rules-of-hooks
    };
  }
  //else if (process.env.REACT_APP_SET_AUTH === 'magic') {
  //  contextValue = {
  //    ...contextValue,
  //   ...useMagicAuth(), // eslint-disable-line react-hooks/rules-of-hooks
  //  };
  // }
  else {
    contextValue = {
      ...contextValue,
      getToken: async (user_identity, room_name) => {
        const endpoint = process.env.REACT_APP_TOKEN_ENDPOINT || '/token';

        return fetch(endpoint, {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify({
            user_identity,
            room_name,
            create_conversation: process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true',
          }),
        }).then(res => res.json());
      },
      updateRecordingRules: async (room_sid, rules) => {
        const endpointRecording = process.env.REACT_APP_RECORDING_ENDPOINT || '/recordingrules';

        return fetch(endpointRecording, {
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ room_sid, rules }),
          method: 'POST',
        })
          .then(async res => {
            const jsonResponse = await res.json();

            if (!res.ok) {
              const recordingError = new Error(
                jsonResponse.error?.message || 'There was an error updating recording rules'
              );
              recordingError.code = jsonResponse.error?.code;
              return Promise.reject(recordingError);
            }

            return jsonResponse;
          })
          .catch(err => setError(err));
      },
    };
  }

  // BELOW IS WORK IN PROGRESS AND NOT USED. WANT BE ABLE TO MAKE IT WORK, BUT CANNOT GET "RES ASSIGNED TO "
  // THIS CAN THEN ALSO BE MADE TO WRITE TESTCASE DATA; ONCE FOR ALL

  //const getTesterData: StateContextType['getTesterData'] = (tester_id) => {
  // setIsFetching(true);
  // return contextValue

  // .getTesterData(tester_id)

  // .then((res) => {
  //console.log("Data" + JSON.stringify(res))
  //setTesterData({id: res._id})
  //  console.log("the data is STRINGIFIED" + res)
  //  setTesterId({id: res) // THIS DOESNT WORK...
  //  setIsFetching(false);
  //  return res;
  //  })
  //  .catch(err => {
  //  setError(err);
  //  setIsFetching(false);
  //  return Promise.reject(err);
  // });
  //};

  const getToken: StateContextType['getToken'] = (name, room) => {
    setIsFetching(true);

    var fields = room.split(' – '); // // REFERENCE TO PREJOINSCREENS AND SETROOMNAME FUNCTION
    var slug = fields[0];

    // TODO: HERE I CAN ADD FOLLOWING HOOK AND ADD THE CONTENT TO THE STATE
    //const testCaseData = useTestCaseData(); // TODO: Refactor so we just use one hook instead of two
    // NEEDS TO BE EXTENDED WITH ID OF TESTCASE SO IT CAN BE USED ALL PLACES

    return contextValue
      .getToken(name, room)
      .then(res => {
        // THIS THEN GETS THE CASE DATA AND WRITES THIS TO THE STATE
        // TODO: THIS SHOULD HAVE ITS OWN FUNCTION, NOT MIXED INTO THIS TOKEN FUNCTON

        console.log('Fetching case data from Sanity...');
        // TODO: ADD THIS AS OWN ENDPOINT ON BE
        sanityClient
          .fetch(
            `*[_type == "testCase" && slug.current == $slug] {
          _updatedAt,
          _createdAt,
          status,
          _id,
        }[0]`,
            {
              slug: slug,
            }
          )
          .then(data => {
            //console.log('Got data from sanity...');
            //console.log("Data for case is id: "+ data._id)
            setCaseData({ id: data._id });
          });

        // sanityTesterClient
        // .fetch(
        //   `*[_type == "tester" && slug.current == $slug] {
        //   _updatedAt,
        //   _createdAt,
        //   status,
        //   firstName,
        //  _id,
        // }[0]`,
        //   {
        //     slug: 'asaa',
        //   }
        // )
        // .then(data => {
        //console.log("Data for case is id: "+ data._id)
        //   setTesterData({id: data._id})
        // })

        setIsFetching(false);
        return res;
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  const updateRecordingRules: StateContextType['updateRecordingRules'] = (room_sid, rules) => {
    setIsFetching(true);
    return contextValue
      .updateRecordingRules(room_sid, rules)
      .then(res => {
        setIsFetching(false);
        return res;
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  return (
    <StateContext.Provider value={{ ...contextValue, getToken, updateRecordingRules }}>
      {props.children}
    </StateContext.Provider>
  );
}

export function useAppState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}
