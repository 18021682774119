import React, { PropsWithChildren } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';

interface AboutDialogProps {
  open: boolean;
  onClose(): void;
}

function Dialog9({ open, onClose }: PropsWithChildren<AboutDialogProps>) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="xs">
      <DialogTitle>Taushetsplinkt</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>
          <p>
            Du godtar full konfidensialitet og taushetsplikt av alt du tester. Som tester vil du kunne teste prototyper,
            konsepter, tjenester som ikke er tilgjengelig på markedet, og du godtar å ikke dele informasjon som du
            tilegner deg gjennom testing.{' '}
          </p>
          <p>
            Du skal ikke gi informasjon til en tredjepart eller snakke om det du har testet. Dette inkluderer innhold i
            testen, informasjon om kunder og informasjon om hva som testes. Du kan ikke kopiere eller registrere noen av
            resultatene fra brukertestingen din. Du kan ikke kopiere eller ta skjermbilder/videoer av testene, og/eller
            avsløre denne informasjonen til andre testere eller tredjeparter, verken personlig, via Internett eller noen
            annen form for kommunikasjon. Du skal ikke lenger bruke det du har testet, etter testen er fullført av deg.
            Hvis tjenestene for brukertesting gir deg tilgang til et passordbeskyttet nettsted eller et ikke-offentlig
            nettsted, skal du ikke bruke dette, etter testen er fullført. 
          </p>
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Dialog9;
