import React, { PropsWithChildren } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';

interface AboutDialogProps {
  open: boolean;
  onClose(): void;
}

function Dialog9({ open, onClose }: PropsWithChildren<AboutDialogProps>) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="xs">
      <DialogTitle>Personvernerklæring for Testere hos Testflow</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>
          <p>
            Din private data er viktig for oss. Testflow AS tilstreber å respektere ditt privatliv, og etterfølge alle
            gjeldende lover og regler rundt innhenting og lagring av persondata via vår plattform.{' '}
          </p>
          <p> Denne personvernerklæringen ble først opprettet 15/01/2022 og oppdatert 27/10/2022.</p>

          <h4>Personinformasjon vi innhenter</h4>
          <p>
            Ved bruk av nettstedet vårt lagres informasjon for innlogging for å tilrettelegge til innloggede tjenester,
            samt sende relevante tester i målgruppen som forespørres av våre kunder. Vi lagrer navn, e-postadresse,
            telefonnummer, samt informasjon du som tester selv frivillig har oppgitt, herunder relevant informasjon som
            for eksempel inntekt, bosted, utdannelse, og atributter om man for eksempel eier bil, går på skole, tar tog,
            spiller spill osv. Vi innhenter ingen persondata fra tredjepartskilder og utleverer ingen data i forbindelse
            med kunders bruk av tjenesten. Vi lagrer også registrerte kontonummer til testere som skal ha godtgjørelse
            for godkjente tester i separate systemer i henhold til Datatilsynets retningslinjer.
          </p>

          <h4>Loggføring av data</h4>
          <p>
            Når du besøker nettstedet vårt kan våre servere automatisk loggføre standard data sendt inn via din
            nettleser. Dette kan inkludere din IP-adresse, nettlesertype og -versjon, sidene du besøker og andre
            detaljer om ditt besøk. Det kan også loggføres tekniske detaljer i forbindelse med eventuelle feil og
            feilmeldinger du opplever på nettsiden. Vennligst vær oppmerksom på at mens denne dataen isolert sett ikke
            kan identifisere deg vil det være teoretisk mulig å kombinere denne dataen med andre, utenforstående
            datakilder for å identifisere individer.
          </p>

          <h4>Innsamling og bruk av informasjon</h4>
          <p>
            Det kan samles inn informasjon fra deg når du gjør følgende aktiviteter på vårt domene: Bruk av mobil enhet
            eller nettleser på PC for å lese innhold på våre sider. Bruk av “kontakt oss” funksjonalitet via e-post,
            chat, sosiale medier eller lignende (Eksempelvis dersom du sender oss e-post eller tagger vår side i sosiale
            medier). Personlig informasjon vil ikke bli videreprosessert og brukt i noen andre sammenhenger enn sin
            oppprinnelige kontekst. Dog vil datapunkter som ikke kan identifisere deg kunne kombineres med annen data
            som vi mottar fra godkjente tredjepartskilder, hvor hensikt er innsikt og analyse av våre digitale flater.
          </p>

          <h4>Din personlige informasjon - sikkerhet</h4>
          <p>
            Når vi samler inn og prosesserer personlig informasjon vil vi beskytte denne mot tap og tyveri, uautorisert
            tilgang, uautorisert deling, kopiering, bruk eller modifisering. Selv om vi vil gjør vårt aller beste
            innenfor lovens krav understrekes det at ingen metode av elektronisk overføring av data er 100% sikker, og
            ingen kan gi en absolutt garanti mot datainnbrudd eller lignende. For løsninger som krever passord og
            lignende er du selv ansvarlig for å sikre styrken på passord eller løsning for login (typisk via din
            e-postadresse).
          </p>

          <h4>Lagring av data</h4>
          <p>
            Vi lagrer kun data så lenge vi har behov i henhold til bestemmelsene i GDPR. Dersom din personlige data ikke
            lenger er nødvendig å lagre vil denne slettes eller anonymiseres. Dog, dersom nødvendig, forbeholder vi oss
            retten til å lagre data som er nødvendig for å etterleve lovgivning vi er underlagt i henhold til norsk lov
            rundt f.eks bokføring, rapportering, juridiske problemstillinger e.l
          </p>

          <h4>Særlig om barns privatliv</h4>
          <p>
            Ingen av våre tjenester er rettet mot barn under 18 år, og vi lagrer ingen informasjon om brukere under 18
            år så langt dette lar seg styre.
          </p>

          <h4>Deling av informasjon til tredjeparter</h4>
          <p>
            Vi forebeholder oss retten til å, ved behov og innenfor gjeldende lovgivning per GDPR og Shrems II, dele
            personlig informasjon til: et morselskap, underselskap eller samarbeidspartner av Testflow AS’
            tredjepartsleverandører hvis hensikten er å muliggjøre leveranse av deres tjenester til oss, herunder
            IT-tjenesteleverandører, datalagring-, hosting- og serverleverandører analyseplattformer, herunder ansatte,
            konsulenter hos nevnte leverandører, samt relevante myndigheter i Norge ved eventuelle pålegg om deling av
            informasjon.
          </p>

          <h4>Internasjonal overføring av personopplysninger</h4>
          <p>
            Personlig informasjon som samles inn er lagret og/eller prosessert hvor våre underleverandører har deres
            fasiliteter. Vi etterstreber å kun benytte underleverandører som er godkjente i henhold til gjeldende
            personvernlovgivning og -retningslinjer, herunder GDPR og Shrems II. Full oversikt over underleverandører
            Testflow bruker kan fåes ved forespørsel.{' '}
          </p>

          <h4>Din data - dine rettigheter</h4>
          <p>
            Du har alltid rett til å tilbakeholde personlig informasjon fra oss med den forståelse av at din opplevelse
            og funksjonalitet med vår tjeneste kan påvirkes. Dersom du gir oss personlig informasjon er dette i
            forståelse med at vi vil kunne sample inn, lagre og bruke dataen i henhold til denne personvernerklæringen.
            Dersom du er en tredjepart som deler personlig data om andre gjør du dette i forståelse med at du da må
            inneha nevnte tredjeparts ettertrykkelige godkjenning. Dersom du tidligere har godkjent å lagre din data hos
            oss kan du når som helst be om at dette slettes eller anonymiseres ved å kontakte oss på chat, e-post eller
            telefon. Dersom du mener vi har data som er unøyaktig, irrelevant, ufullstendig eller misledende eller
            utdatert ber vi deg kontakte oss for å rette på dette. Dersom du mener vi har brutt vilkår i denne
            personvernerklæringen og/eller gjeldende lovgivning ber vi om at du tar kontakt og gir oss alle detaljer om
            et eventuelt brudd på vilkår/lovgivning. Vi vil snarest undersøke saken og dele alle detaljer med deg
            skriftlig, inkludert resultatet av våre interne undersøkelser og eventuell konklusjon. Du har også rett til
            å kontakte Datatilsynet dersom du mener vi bryter lovgivningen.
          </p>

          <h4>Bruk av cookies</h4>
          <p>
            Vi bruker “cookies” for å samle informasjon om dine aktiviteter på tvers av våre digitale flater. En
            “Cookie” er et identifiserbart stykke data som lagres på din nettleser og som f.eks kan fortelle oss om
            gjentatte besøk på våre nettsider e.l. Dette hjelper oss optimalisere din opplevelse på våre digitale
            flater.{' '}
          </p>

          <h4>Begrensninger i denne personvernerklæringen</h4>
          <p>
            Våre digitale flater kan lenke til eksterne nettsteder og lignende som ikke eies og opereres av oss,
            herunder sosiale medieplattformer. Vennligst vær oppmerksom på at vi ikke har kontroll over innhold og
            policies på eksterne nettsteder som ikke eier av oss og kan ikke påta oss ansvar for deres personverntiltak.
          </p>

          <h4>Endringer i personvernerklæringen</h4>
          <p>
            Internasjonal overføring av personopplysninger Personlig informasjon som samles inn er lagret og/eller
            prosessert hvor våre underleverandører har deres fasiliteter. Vi etterstreber å kun benytte
            underleverandører som er godkjente i henhold til gjeldende personvernlovgivning og -retningslinjer, herunder
            GDPR og Shrems II, men kan ikke gi noen garantier på deres vegne. Full oversikt over underleverandører samt
            lenke til deres personvernerklæringer finnes i slutten av dette dokumentet.{' '}
          </p>

          <h4>Endringer i denne personvernerklæringen</h4>
          <p>
            Vi forbeholder oss retten til å oppdatere denne personvernerklæringen i henhold til oppdateringer i våre
            forretningsprosesser, ved endringer i lovgivning, herunder oppdateringer/endringer av vilkår under GDPR og
            Shrems II. Dersom loven krever det vil vi informere om endringer og gi deg muligheten til å trekke
            eventuelle samtykker med tanke på dine personopplysninger.
          </p>
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Dialog9;
