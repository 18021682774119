import { useEffect, useState } from 'react';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { useApiGet, TApiResponse } from '../../hooks/useApiGet/useApiHook';

//import sanityClient from '../../sanity';

export default function useStepsState() {
  const [allTestCaseData, setAllTestCaseData] = useState<any>([]);
  const { room } = useVideoContext();
  //const [roomSlug, setRoomSlug] = useState<string>('');

  //const [loadingComplete, setRoomSlug] = useState<string>('');

  var roomName = room!.name;

  /*
  useEffect(() => {
    if (roomName) {
      var fields = roomName.split(' – ');
      setRoomSlug(fields[0]);
    }
  }, [roomName]);
  */

  var fields = roomName.split(' – ');

  //console.log('Romnavn er: ' + roomName);
  //console.log('ROMID er: ' + roomSlug);

  interface TestCaseData {
    data: any;
  }

  const [testCaseDataState, testCaseDataStateSet] = useState<TestCaseData>({} as TestCaseData);
  const [loadingDataComplete, setLoadingDataComplete] = useState(false);

  const baseEndpoint = process.env.REACT_APP_GET_TESTCASEDATA_ENDPOINT;
  const testcaseEndpoint = baseEndpoint ? `${baseEndpoint}${fields[0]}` : 'missing endpoint';

  const testCaseData: TApiResponse = useApiGet(testcaseEndpoint);

  useEffect(() => {
    if (!loadingDataComplete) {
      if (!testCaseData.loading && testCaseData.status === 200) {
        //console.log(data);
        testCaseDataStateSet(testCaseData as TestCaseData);
        setLoadingDataComplete(true);
      }
    }
  }, [testCaseData, loadingDataComplete]);

  useEffect(() => {
    //console.log('Fetching steps');
    if (loadingDataComplete) {
      //console.log('Done fetching steps');
      let steps: any[] = testCaseDataState?.data?.body?.data?.steps;
      let panelSelection: string = testCaseDataState?.data?.body?.data?.panelSelection;
      let status: string = testCaseDataState?.data?.body?.data?.status;
      let type: string = testCaseDataState?.data?.body?.data?.type;

      setAllTestCaseData({ steps: steps, data: { panelSelection: panelSelection, status: status, type: type } });
    }
  }, [loadingDataComplete, testCaseDataState]);

  /*
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "testCase" && slug.current == $slug] {
            _updatedAt,
            _createdAt,
            _id,
            "title" : title,
            steps,
            panelSelection
        }[0]`,
        {
          slug: roomSlug,
        }
      )
      .then(data => {
        setAllTestCaseData({ steps: data.steps, data: { panelSelection: data.panelSelection } });
      })
      .catch(console.error);
  }, [roomSlug]);
 */
  return allTestCaseData;
}
