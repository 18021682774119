import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import StartRecordingIcon from '../../../icons/StartRecordingIcon';
import StopRecordingIcon from '../../../icons/StopRecordingIcon';
//import useScreenShareParticipant from '../../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { useAppState } from '../../../state';
import useIsRecording from '../../../hooks/useIsRecording/useIsRecording';

//import { Participant, TrackPublication } from 'twilio-video';

import {
  styled,
  //Typography,
} from '@material-ui/core';

export const IconContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  width: '1.5em',
  marginRight: '0.3em',
  color: 'white',
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      borderRadius: 25,
      background: theme.brandWhite,
      color: theme.brand,
      '&:hover': {
        background: theme.brandWhiteDark,
        color: theme.brand,
      },
      '&[disabled]': {
        color: '#bbb',
        '& svg *': {
          fill: '#bbb',
        },
      },
    },
  })
);

export default function ToggleRecordButton(props: { disabled?: boolean; className?: string }) {
  const classes = useStyles();
  //const screenShareParticipant = useScreenShareParticipant();
  //const { toggleScreenShare } = useVideoContext();
  //const disableScreenShareButton = Boolean(screenShareParticipant);
  //const isScreenShareSupported = navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia;
  //const isDisabled = props.disabled || disableScreenShareButton || !isScreenShareSupported;
  //const { isFetching, updateRecordingRules, roomType } = useAppState();
  const { isFetching, updateRecordingRules, endTest, startTest, caseData, redirectionSuccess } = useAppState();
  const isRecording = useIsRecording();
  //const { room, setIsBackgroundSelectionOpen } = useVideoContext();
  const { room } = useVideoContext();

  return (
    <Button
      disabled={isFetching}
      className={classes.button}
      onClick={() => {
        if (isRecording) {
          updateRecordingRules(room!.sid, [{ type: 'exclude', all: true }]);
          endTest(room!.name, room!.sid, caseData!.id, redirectionSuccess);
        } else {
          updateRecordingRules(room!.sid, [{ type: 'include', all: true }]);
          startTest(room!.name, room!.sid, caseData!.id);
        }
      }}
      data-cy-recording-button
    >
      <IconContainer>{isRecording ? <StopRecordingIcon /> : <StartRecordingIcon />}</IconContainer>
      {isRecording ? 'Stopp' : 'Start'} opptak
    </Button>
  );
}
