import React, { useCallback } from 'react';

import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useAppState } from '../../../state';

import { Button } from '@material-ui/core';

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: theme.brandSecondary,
      color: 'white',
      '&:hover': {
        background: theme.brandSecondaryLight,
      },
    },
  })
);

// Helper function to check for valid URL
function isValidUrl(urlString: string): boolean {
  try {
    new URL(urlString);
    return true;
  } catch (e) {
    return false;
  }
}

export default function EndCallButton(props: { className?: string }) {
  const classes = useStyles();
  const { room } = useVideoContext();
  const { signOut, redirectionScreenout } = useAppState();
  const { localTracks } = useVideoContext();

  const handleSignOut = useCallback(() => {
    room!.disconnect();
    localTracks.forEach(track => track.stop());

    // Check if redirectionScreenout is a valid URL and pass it to signOut if it is
    if (redirectionScreenout && isValidUrl(redirectionScreenout)) {
      signOut!(redirectionScreenout);
    } else {
      signOut?.();
    }
  }, [room, localTracks, signOut, redirectionScreenout]);

  return (
    <Button onClick={handleSignOut} className={clsx(classes.button, props.className)} data-cy-disconnect>
      Forlat testrom
    </Button>
  );
}
