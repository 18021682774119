import React, { useState } from 'react';
import { MuiThemeProvider } from '@material-ui/core';
import { getThemeByName } from './themes/base';

export const ThemeContext = React.createContext((themeName: string): void => {});

const ThemeProvider: React.FC = props => {
  // Read current theme from localStorage or maybe from an api
  const curThemeName = localStorage.getItem('appTheme') || 'defaultTheme';

  // State to hold the selected theme name
  const [themeName, _setThemeName] = useState(curThemeName);

  // Get the theme object by theme name
  const theme = getThemeByName(themeName);

  const setThemeName = (themeName2: string): void => {
    localStorage.setItem('appTheme', themeName2);
    _setThemeName(themeName2);
  };

  return (
    <ThemeContext.Provider value={setThemeName}>
      <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
