import sanityClient from '@sanity/client';

// THIS IS THE DATA FROM TEST CLIENT SANITY STUDIO
export default sanityClient({
  projectId: 'qfviotp0',
  dataset: 'production',
  token:
    'sk5QYnHpvA2AmNnENkJIEDlAYrM7hBctMhTdNSjEWEI19axbhLkNSUXHCU1tLir4CwQyHvnQHEZU5j5lDQU2byFLlwur5RiRQoIp6mk4oi30vqMTMUb49ivFkKZOl8rLYDvHPt22LFSfupIV3wjrKJbUtxOSrk3SpjfI3hzMvFKB4Yuog1sy',
  apiVersion: '2021-12-12', // use a UTC date string
  useCdn: false, // `false` if you want to ensure fresh data
});
