import React, { ChangeEvent, useState, useEffect, FormEvent, useMemo, useContext } from 'react';
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen';
import DeviceSelectionScreenSimple from './DeviceSelectionScreen/PreviewConnectScreen';

import IntroContainer from '../IntroContainer/IntroContainer';
import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar';
import RoomNameScreen from './RoomNameScreen/RoomNameScreen';
import { useAppState } from '../../state';
import { useParams } from 'react-router-dom';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { useApiGet, TApiResponse } from '../../hooks/useApiGet/useApiHook';
import { Typography, makeStyles, Theme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { InputLabel } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { ThemeContext } from '../../ThemeProvider';

import NorstatLogo from '../../images/norstat_logo_dark.png';

import Dialog7 from '../Dialogs/Dialog7/Dialog7';
import Dialog8 from '../Dialogs/Dialog8/Dialog8';
import Dialog9 from '../Dialogs/Dialog9/Dialog9';
import Dialog10 from '../Dialogs/Dialog10/Dialog10';
//import Dialog11 from '../Dialogs/Dialog11/Dialog11';

export enum Steps {
  roomNameStep,
  defaultInfo,
  deviceSelectionStep,
  deviceSelectionSimple,
  addMailAddress,
  testIsFull,
  testIsExpired,
  noAccess,
  anonymous,
  loadingStep,
  addAlias,
  prepare,
  instructions,
  norstatAddAlias,
  norstatPrepare,
  norstatInstructions,
  norstatPaymentInfo,
  norstatTerms,
  error,
}

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  gutterTop: {
    marginTop: '1em',
    marginBottom: '0',
  },
  marginBottom: {
    marginBottom: '1em',
  },
  gutterBottom2: {
    marginBottom: '0.5em',
  },
  noMargin: {
    margin: 0,
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1.5em 0 3.5em',
    '& div:not(:last-child)': {
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '1.5em 0 2em',
    },
  },
  textFieldContainer: {
    width: '100%',
  },
  link: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: theme.brandDark,
  },
  paddingList: {
    '& ul': {
      paddingLeft: '18px',
    },
  },
  logoutLink: {
    cursor: 'pointer',
  },
  passcodeContainer: {
    //minHeight: '120px',
  },
  submitButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  submitButtonMargin: {
    marginLeft: '20px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  lblCheckbox: {
    display: 'inline-block',
    padding: '2px',
    background: '#fff',
    cursor: 'pointer',
  },
  checkBoxText: {
    marginBottom: '0.1em',
  },
  checkBox: {
    marginRight: '10px',
    fontSize: '20em',
    padding: '5em',
    cursor: 'pointer',
  },
  submitButton2: {
    //marginLeft: '20px',
    height: '2.5em',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  submitButtonCancel: {
    marginRight: '10px',
    backgroundColor: '#c8c6c6',
    height: '2.5em',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  continueButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

export function getPasscode() {
  const match = window.location.search.match(/passcode=(.*)&?/);
  const passcode = match ? match[1] : window.sessionStorage.getItem('passcode');
  return passcode;
}

function cleanUserId(userId: string): string | null {
  // This function cleans up the userID to not contain any other url parameters (for instance redirection links)
  // Directly extract the part of userId before any '&' character
  const cleanId = userId.split('&')[0];

  // Validate the extracted part to ensure it contains only alphanumeric characters
  const pattern = /^[a-zA-Z0-9]+$/;
  if (pattern.test(cleanId)) {
    return cleanId; // Return the clean userId if it matches the pattern
  }

  return null; // Return null if the extracted part doesn't match the expected format
}

export default function PreJoinScreens() {
  const classes = useStyles();
  const { user, redirectionScreenout } = useAppState();

  const setThemeName = useContext(ThemeContext);
  const { getAudioAndVideoTracks } = useVideoContext();
  const { URLRoomName } = useParams();
  const [step, setStep] = useState(Steps.loadingStep);
  const [name, setName] = useState<string>('');
  const [roomName, setRoomName] = useState<string>('');
  const [alias, setAlias] = useState('');
  const [email, setEmail] = useState('');
  //const [companyName, setCompanyName] = useState<string>('');
  const [norstatId, setNorstatId] = useState<string>('');
  //const [sharedId, setSharedId] = useState<string>('');
  const [roomId, setRoomId] = useState<string>();
  const [testType, setTestType] = useState<string>('');

  const [dialog7Open, setDialog7Open] = useState(false);
  const [dialog8Open, setDialog8Open] = useState(false);
  //const [dialog9Open, setDialog9Open] = useState(false);
  const [dialog10Open, setDialog10Open] = useState(false);
  //const [dialog11Open, setDialog11Open] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  //const [userId, setUserId] = useState<string>('');
  const [mediaError, setMediaError] = useState<Error>();

  const [loadingDataComplete, setLoadingDataComplete] = useState(false);
  const [loadingUserDataComplete, setLoadingUserDataComplete] = useState(false);

  const [roomSlug, setRoomSlug] = useState<string>('');
  const [userId, setuserId] = useState<string>('');

  const passcode = getPasscode();

  const fields = useMemo(() => {
    return passcode ? passcode.split('-') : [];
  }, [passcode]);

  function isValidUrl(urlString: string): boolean {
    try {
      new URL(urlString);
      return true;
    } catch (e) {
      return false;
    }
  }

  useEffect(() => {
    if (fields.length > 0) {
      if (fields[0]) {
        setRoomSlug(fields[0]);
      }
      if (fields.length > 1 && fields[1]) {
        const cleanUserIdValue = cleanUserId(fields[1]);
        if (cleanUserIdValue) {
          setuserId(cleanUserIdValue); // Set the cleaned userId
        }
      }
    }
  }, [fields, passcode]);

  interface UserData {
    data: any;
  }

  interface TestCaseData {
    data: any;
  }

  const dataEndpoint = process.env.REACT_APP_GET_TESTCASE_ENDPOINT || 'missing endpoint'; // SHOULD BE IMPROVED
  const userDataEndpoint = process.env.REACT_APP_GET_USERDATA || 'missing endpoint'; // SHOULD BE IMPROVED

  const data: TApiResponse = useApiGet(dataEndpoint + [fields[0]]);

  const userdata: TApiResponse = useApiGet(userDataEndpoint + [fields[1]]);

  const [userDataState, userDataStateSet] = useState<UserData>({} as UserData);
  const [testCaseDataState, testCaseDataStateSet] = useState<TestCaseData>({} as TestCaseData);

  useEffect(() => {
    if (!loadingDataComplete) {
      if (!data.loading && data.status === 200) {
        //console.log(data);
        testCaseDataStateSet(data as TestCaseData);
        setLoadingDataComplete(true);
      }
    }
  }, [data, loadingDataComplete]);

  useEffect(() => {
    if (!loadingUserDataComplete) {
      if (!userdata.loading && userdata.status === 200) {
        //console.log(userdata);
        userDataStateSet(userdata as UserData);
        setLoadingUserDataComplete(true);
      }
    }
  }, [userdata, loadingUserDataComplete]);

  useEffect(() => {
    if (loadingDataComplete) {
      if (testCaseDataState?.data?.body?.data?.status) {
        let status: string = testCaseDataState?.data?.body?.data?.status;
        let panelSelection: string = testCaseDataState?.data?.body?.data?.panelSelection;
        let company: string = testCaseDataState?.data?.body?.data?.company;

        if (panelSelection === 'testflow') {
          setThemeName('testflowDarkTheme'); // THIS CAUSES A DOUBLE RENDERING; COULD BE IMPROVED

          if (loadingUserDataComplete) {
            switch (status) {
              case 'draft': {
                //let firstName: string = userDataState.data.body.data.firstName;
                setName('Forhåndsvisning');
                setRoomName(roomSlug + ' – Forhåndsvisning – ' + Math.floor(Math.random() * 10001));
                //setStep(Steps.roomNameStep);
                setStep(Steps.deviceSelectionSimple); // this redirects to the room without any camera configuration
                //setStep(Steps.defaultInfo);

                break;
              }
              case 'review': {
                setStep(Steps.noAccess);
                break;
              }
              case 'live': {
                let firstName: string = userDataState.data.body.data.firstName;
                //setUserId(fields[1]);
                setName(firstName);
                setRoomName(roomSlug + ' – ' + firstName + ' – ' + userId); // this is important as this is used later on to fetch right sanity script
                //setStep(Steps.roomNameStep);
                setStep(Steps.defaultInfo);

                break;
              }
              case 'ended': {
                setStep(Steps.testIsFull);
                break;
              }
              case 'archived': {
                setStep(Steps.testIsExpired);
                break;
              }
              case 'paused': {
                setStep(Steps.testIsFull);
                break;
              }
              default: {
                setStep(Steps.testIsFull);
                break;
              }
            }
          }
        }

        if (panelSelection === 'internal') {
          setThemeName('testflowDarkTheme'); // THIS CAUSES A DOUBLE RENDERING; COULD BE IMPROVED

          switch (status) {
            case 'draft': {
              setName('Forhåndsvisning');
              setRoomName(roomSlug + ' – Forhåndsvisning – ' + Math.floor(Math.random() * 10001));
              //setStep(Steps.roomNameStep);
              setStep(Steps.deviceSelectionSimple); // this redirects to the room without any camera configuration

              //setRoomId(roomSlug); // This line can probably been refactored
              //setTestType('sharedUrl'); // This line can probably been refactored
              //setName(company); // This line can probably been refactored
              //setStep(Steps.prepare);
              break;
            }
            case 'review': {
              setStep(Steps.noAccess);
              break;
            }
            case 'live': {
              setRoomId(roomSlug); // This line can probably been refactored
              setTestType('sharedUrl'); // This line can probably been refactored
              setName(company); // This line can probably been refactored
              setStep(Steps.prepare);
              break;
            }
            case 'ended': {
              setStep(Steps.testIsFull);
              break;
            }
            case 'archived': {
              setStep(Steps.testIsExpired);
              break;
            }
            case 'paused': {
              setStep(Steps.testIsFull);
              break;
            }
            default: {
              setStep(Steps.testIsFull);
              break;
            }
          }
        }

        if (panelSelection === 'norstat') {
          setThemeName('norstatTheme'); // THIS CAUSES A DOUBLE RENDERING; COULD BE IMPROVED

          switch (status) {
            case 'draft': {
              setName('Forhåndsvisning');
              setRoomName(roomSlug + ' – Forhåndsvisning - ' + Math.floor(Math.random() * 10001) + ' – norstat');

              //setStep(Steps.roomNameStep);
              setStep(Steps.deviceSelectionSimple); // this redirects to the room without any camera configuration

              //setTestType('norstat'); // REFERENCING usepasscodeAuth.ts line 188
              //setName(company); // CHECK UP IF THIS IS SMART
              //setRoomId(roomSlug); // This line can probably been refactored
              //setNorstatId(userId);
              //setStep(Steps.norstatPrepare);
              break;
            }
            case 'review': {
              setName('Forhåndsvisning');
              setRoomName(roomSlug + ' – Forhåndsvisning – ' + Math.floor(Math.random() * 10001));
              //setStep(Steps.roomNameStep);
              setStep(Steps.deviceSelectionSimple); // this redirects to the room without any camera configuration

              //setTestType('norstat'); // REFERENCING usepasscodeAuth.ts line 188
              //setName(company); // CHECK UP IF THIS IS SMART
              //setRoomId(roomSlug); // This line can probably been refactored
              //setNorstatId(userId);
              //setStep(Steps.norstatPrepare);
              break;
            }
            case 'live': {
              setTestType('norstat'); // REFERENCING usepasscodeAuth.ts line 188
              setName(company); // CHECK UP IF THIS IS SMART
              setRoomId(roomSlug); // This line can probably been refactored
              setNorstatId(userId);
              setStep(Steps.norstatPrepare);

              break;
            }
            case 'ended': {
              setStep(Steps.testIsFull);

              if (isValidUrl(redirectionScreenout)) {
                window.location.href = redirectionScreenout;
              } else {
                window.location.href = 'https://webs.norstatsurveys.com/z/QuotaFull';
              }
              break;
            }
            case 'archived': {
              setStep(Steps.testIsExpired);
              break;
            }
            case 'paused': {
              setStep(Steps.testIsFull);
              break;
            }
            default: {
              setStep(Steps.testIsFull);
              break;
            }
          }
        }
      }
    }
  }, [
    loadingDataComplete,
    loadingUserDataComplete,
    testCaseDataState,
    userDataState,
    setThemeName,
    userId,
    roomSlug,
    redirectionScreenout,
  ]);

  // CHECK IF THIS IS USED AT ALL. Seems not in use, so can probably be deleted
  useEffect(() => {
    if (URLRoomName) {
      setRoomName(URLRoomName);

      if (user?.displayName) {
        setStep(Steps.deviceSelectionStep);
      }
    }
  }, [user, URLRoomName]);

  useEffect(() => {
    if (step === Steps.deviceSelectionStep && !mediaError) {
      getAudioAndVideoTracks().catch(error => {
        console.log('Error acquiring local media:');
        console.dir(error);
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, step, mediaError]);

  const handleTerms = () => {
    if (termsAccepted) {
      setTermsAccepted(false);
    } else {
      setTermsAccepted(true);
    }
  };

  const handleAddEmail = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (email) {
      setRoomName(roomName + ' – ' + email); // Adding a random number to make sure users not enter the same room, if they have the same name and enters at the same time
    }
    setStep(Steps.deviceSelectionStep);
  };

  const handleAddAlias = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (alias) {
      setRoomName(roomId + ' – ' + alias + ' – ' + Math.floor(Math.random() * 100) + ' – ' + testType); // Adding a random number to make sure users not enter the same room, if they have the same name and enters at the same time
      //setStep(Steps.deviceSelectionStep);
      setName(alias);
      setStep(Steps.addMailAddress);
    }
  };

  const handleAddAliasNorstat = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (alias) {
      setRoomName(roomId + ' – ' + alias + ' – ' + norstatId + ' – ' + testType);
      setName(alias);
      setStep(Steps.deviceSelectionStep);
    }
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // If this app is deployed as a twilio function, don't change the URL because routing isn't supported.
    if (!window.location.origin.includes('twil.io')) {
      window.history.replaceState(null, '', window.encodeURI(`/room/${roomName}${window.location.search || ''}`));
    }
    setStep(Steps.deviceSelectionStep);
  };

  return (
    <IntroContainer>
      <MediaErrorSnackbar error={mediaError} />

      {step === Steps.loadingStep && <div>Laster...</div>}

      {step === Steps.roomNameStep && (
        <RoomNameScreen
          name={name}
          roomName={roomName}
          setName={setName}
          setRoomName={setRoomName}
          handleSubmit={handleSubmit}
        />
      )}

      {step === Steps.defaultInfo && (
        <>
          <Typography variant="h5" className={classes.gutterBottom}>
            Hei {name}!
          </Typography>

          <Typography variant="body1" className={classes.paddingList}>
            <ul>
              <li>I denne testen skal du nå gjøre en brukertest hvor du sier din mening via video og skjermdeling.</li>
              <li>Testen må tas på en PC eller Mac</li>
            </ul>
          </Typography>

          <Grid container className={classes.marginBottom}>
            <label className={classes.lblCheckbox}>
              <input type="checkbox" onChange={() => handleTerms()} className={classes.checkBox} />
            </label>
            <Typography variant="body1" className={classes.checkBoxText}>
              Ved å gå videre så aksepterer du våre{' '}
              <span className={classes.link} onClick={() => setDialog7Open(true)}>
                vilkår
              </span>{' '}
              og regler for{' '}
              <span className={classes.link} onClick={() => setDialog8Open(true)}>
                personvern
              </span>
            </Typography>
          </Grid>

          <Grid container justifyContent="space-between">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!termsAccepted}
              onClick={() => setStep(Steps.deviceSelectionStep)}
              className={classes.submitButton2}
            >
              Neste
            </Button>
            {/*<img src={partnerLogo} width="150px" alt="Partner logo" /> */}
          </Grid>
        </>
      )}

      {step === Steps.deviceSelectionSimple && (
        <DeviceSelectionScreenSimple name={name} roomName={roomName} setStep={setStep} />
      )}

      {step === Steps.deviceSelectionStep && (
        <DeviceSelectionScreen name={name} roomName={roomName} setStep={setStep} />
      )}

      {step === Steps.prepare && (
        <>
          <Typography variant="h5" className={classes.gutterBottom}>
            I denne testen skal du nå gjøre en brukertest hvor du sier din mening via video og skjermdeling
          </Typography>

          <Typography variant="body1" className={classes.paddingList}>
            <ul>
              <li>Testen må tas på en PC eller Mac</li>
              <li>
                Du trenger et fungerende webkamera og mikrofon for å teste. De fleste bærbare PC-er har dette
                innebygget.
              </li>
            </ul>
          </Typography>

          <Grid container className={classes.marginBottom}>
            <input type="checkbox" onChange={() => handleTerms()} className={classes.checkBox} />
            <Typography variant="body1" className={classes.checkBoxText}>
              Ved å gå videre så aksepterer du våre{' '}
              <span className={classes.link} onClick={() => setDialog7Open(true)}>
                vilkår
              </span>{' '}
              og regler for{' '}
              <span className={classes.link} onClick={() => setDialog8Open(true)}>
                personvern
              </span>
            </Typography>
          </Grid>

          <Grid container justifyContent="space-between">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!termsAccepted}
              onClick={() => setStep(Steps.instructions)}
              className={classes.submitButton2}
            >
              Neste
            </Button>
            {/*<img src={partnerLogo} width="150px" alt="Partner logo" /> */}
          </Grid>
        </>
      )}

      {step === Steps.instructions && (
        <>
          <Typography variant="h5" className={classes.gutterBottom}>
            Gjør deg klar!
          </Typography>

          <Typography variant="h6">Pass på det er rolig rundt deg</Typography>
          <Typography variant="body1" className={classes.gutterBottom}>
            Det vil bli tatt opp video av ansiktet ditt og det du deler på skjermen. Så pass på at det er rolig rundt
            deg og at alt personlig er lukket på skjermen du deler.
          </Typography>

          <Typography variant="h6">Tenk høyt under testen</Typography>
          <Typography variant="body1" className={classes.gutterBottom}>
            Les oppgavene høyt og si alt du tenker når du utfører testen.
          </Typography>

          <Grid container justifyContent="space-between">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={() => setStep(Steps.addAlias)}
              className={classes.submitButton2}
            >
              Neste
            </Button>
            {/*<img src={partnerLogo} width="150px" alt="Partner logo" /> */}
          </Grid>
        </>
      )}

      {step === Steps.addMailAddress && (
        <>
          <Typography variant="h5" className={classes.gutterBottom2}>
            Din mailadresse
          </Typography>
          <Typography variant="body1" className={classes.gutterBottom}>
            Du kan valgfritt legge inn din mailadresse for å få mer informasjon etter fullført test. Det kan være
            informasjon tilknyttet godkjennelse, samtykke eller honorar.{' '}
          </Typography>

          <form onSubmit={handleAddEmail}>
            <Grid container justifyContent="space-between">
              <div className={classes.passcodeContainer}>
                <InputLabel shrink htmlFor="name">
                  Mail-adresse: (valgfritt)
                </InputLabel>
                <TextField
                  id="mail"
                  //type="mail"
                  //name="mail"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                  variant="outlined"
                  size="small"
                />
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  //disabled={!alias.length}
                  className={classes.submitButtonMargin}
                >
                  Neste
                </Button>
              </div>
              {/*<img src={partnerLogo} width="150px" alt="Partner logo" /> */}
            </Grid>
          </form>
        </>
      )}

      {step === Steps.addAlias && (
        <>
          <Typography variant="h5" className={classes.gutterBottom2}>
            Skriv inn et navn
          </Typography>
          <Typography variant="body1" className={classes.gutterBottom}>
            Du kan skrive inn ditt fornavn eller kallenavn som representerer deg.{' '}
          </Typography>
          <form onSubmit={handleAddAlias}>
            <Grid container justifyContent="space-between">
              <div className={classes.passcodeContainer}>
                <InputLabel shrink htmlFor="fname">
                  Ditt fornavn
                </InputLabel>
                <TextField
                  id="fname"
                  //type="fname"
                  //name="fname"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setAlias(e.target.value)}
                  variant="outlined"
                  size="small"
                />
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={!alias.length}
                  className={classes.submitButtonMargin}
                >
                  Neste
                </Button>
              </div>
              {/*<img src={partnerLogo} width="150px" alt="Partner logo" /> */}
            </Grid>
          </form>
        </>
      )}

      {step === Steps.norstatPrepare && (
        <>
          <Typography variant="h5" className={classes.gutterBottom}>
            I denne testen skal du nå gjøre en brukertest hvor du sier din mening via video og skjermdeling
          </Typography>

          <Typography variant="body1" className={classes.paddingList}>
            <p>
              Norstat Norge AS samarbeider med Testflow AS for å kunne gjennomføres slike brukertester på desktop og
              disse vilkårene beskriver hvilke rettigheter og plikter som gjelder mellom deg, Norstat og Testflow.
            </p>

            <p>
              Du har godkjent og samtykket til vilkårene når du aktivt svarer på spørsmålet «Jeg samtykker og godkjenner
              vilkårene» presentert til slutt.
              {/*}
          <Typography variant="h5">Opptak og bruk av opptak</Typography>

          <p>Du forstår og godtar at hver test du tar er tatt opp i et opptak og at opptaket inkluderer video fra ditt webkamera, lydopptak av stemmen din og opptak av skjermen din. </p>

          <p>Du forstår og godtar videre at Testflow og Norstat vil gi en kopi av opptaket til kunden som har opprettet testen, og at opptaket kan bli sett og brukt av oppdragsgiver i sitt analysearbeid og vises internt i organisasjonen. Testflow og Norstat har også tilgang til opptakene. 

          <Typography variant="h5">Taushetsplikt</Typography>
          <p>Du godtar full konfidensialitet og taushetsplikt av alt du tester. Som tester vil du kunne teste prototyper, konsepter, tjenester som ikke er tilgjengelig på markedet, og du godtar å ikke dele informasjon som du tilegner deg gjennom testing. Du skal ikke gi informasjon til en tredjepart eller snakke om det du har testet. Dette inkluderer innhold i testen, informasjon om kunder og informasjon om hva som testes. Du kan ikke kopiere eller registrere noen av resultatene fra brukertestingen din. Du kan ikke kopiere eller ta skjermbilder/videoer av testene, og/eller avsløre denne informasjonen til andre testere eller tredjeparter, verken personlig, via Internett eller noen annen form for kommunikasjon. Du skal ikke lenger bruke det du har testet, etter testen er fullført av deg.
          Hvis tjenestene for brukertesting gir deg tilgang til et passordbeskyttet nettsted eller et ikke-offentlig nettsted, skal du ikke bruke dette, etter testen er fullført. </p>
                </p> */}
            </p>
          </Typography>

          <Grid container justifyContent="space-between">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={() => setStep(Steps.norstatTerms)}
              className={classes.submitButton2}
            >
              Neste
            </Button>
            <img src={NorstatLogo} width="150px" alt="Norstat logo" />
          </Grid>
        </>
      )}

      {step === Steps.norstatTerms && (
        <>
          <Typography variant="h5" className={classes.gutterBottom}>
            Samtykke og vilkår
          </Typography>

          <Typography variant="h6" className={classes.noMargin}>
            Opptak og bruk av opptak
          </Typography>

          <p>
            Du forstår og godtar at hver test du tar er tatt opp i et opptak og at opptaket inkluderer video fra ditt
            webkamera, lydopptak av stemmen din og opptak av skjermen din. 
          </p>

          <p className={classes.gutterBottom}>
            Du forstår og godtar videre at Testflow og Norstat vil gi en kopi av opptaket til kunden som har opprettet
            testen, og at opptaket kan bli sett og brukt av oppdragsgiver i sitt analysearbeid og vises internt i
            organisasjonen. Testflow og Norstat har også tilgang til opptakene.
            <Typography variant="h6" className={classes.gutterTop}>
              Taushetsplikt
            </Typography>
            <p>
              Du godtar full konfidensialitet og taushetsplikt av alt du tester. Som tester vil du kunne teste
              prototyper, konsepter, tjenester som ikke er tilgjengelig på markedet, og du godtar å ikke dele
              informasjon som du tilegner deg gjennom testing.{' '}
              <span className={classes.link} onClick={() => setDialog10Open(true)}>
                Les mer
              </span>
            </p>
          </p>

          <Grid container justifyContent="space-between">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={() => setStep(Steps.norstatPaymentInfo)}
              className={classes.submitButton2}
            >
              Neste
            </Button>
            <img src={NorstatLogo} width="150px" alt="Norstat logo" />
          </Grid>
        </>
      )}

      {step === Steps.norstatPaymentInfo && (
        <>
          <Typography variant="h5" className={classes.gutterBottom}>
            Godtgjørelse for godkjente tester
          </Typography>

          <Typography variant="body1" className={classes.gutterBottom}>
            Norstat godtgjør bare for godkjente tester og dersom vilkårene på denne siden er oppfylt:
          </Typography>
          <Typography variant="body1" className={classes.gutterBottom}>
            <ul>
              <li>At vi kan se tydelig video av deg som tester</li>
              <li>At du snakker høyt og tydelig under testen</li>
              <li>At vi ser skjermdeling av det du tester</li>
              <li>At du svarer på alle spørsmålene som blir stilt</li>
              <li>At du bruker den tiden som er oppgitt i testlengden, henholdsvis 5, 10, eller 20 minutter </li>
            </ul>
          </Typography>

          <Typography variant="body1" className={classes.gutterBottom}>
            Som Norstatpanelist vil du som vanlig motta dine Norstatmynter etter at du har gjennomført denne
            brukertesten. Dersom testen din er godkjent vil du motta de resterende Norstatmyntene innen en ukes tid. For
            spørsmål angående disse vilkårene, vennligst kontakt panelsupport@norstat.no
            <p>
              Ved å gå videre nå så aksepterer du vilkårene. Dersom du ikke ønsker å delta kan du lukke og avslutte.
            </p>
          </Typography>

          <Grid container justifyContent="space-between">
            <div>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={() => {
                  if (isValidUrl(redirectionScreenout)) {
                    window.location.href = redirectionScreenout;
                  } else {
                    window.location.href = 'https://www.norstatpanel.com/nb';
                  }
                }}
                className={classes.submitButtonCancel}
              >
                Lukk og avslutt
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={() => setStep(Steps.norstatInstructions)}
                className={classes.submitButton2}
              >
                Gå videre
              </Button>
            </div>
            <img src={NorstatLogo} width="150px" alt="Norstat logo" />
          </Grid>
        </>
      )}

      {step === Steps.norstatInstructions && (
        <>
          <Typography variant="h5" className={classes.gutterBottom}>
            Tips! Før du starter så husk:
          </Typography>

          <Typography variant="body1" className={classes.gutterBottom}>
            <ul>
              <li>Å dele riktig skjerm dersom du bruker to skjermer</li>
              <li>Ta deg god tid og ikke rush gjennom oppgavene</li>
              <li>Tenk deg godt om og prøv å gi så ærlige svar som mulig</li>
            </ul>
          </Typography>

          {/*
          <Typography variant="body1" className={classes.gutterBottom}>
            Dersom du ønsker å se et eksempel på hvordan en brukertest gjennomføres, så klikk{' '}
            <span className={classes.link} onClick={() => setDialog11Open(true)}>
              her for
            </span>{' '}
            for å se et eksempel.{' '}
          </Typography>
      */}

          <Grid container justifyContent="space-between">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={() => setStep(Steps.norstatAddAlias)}
              className={classes.submitButton2}
            >
              Neste
            </Button>
            <img src={NorstatLogo} width="150px" alt="Norstat logo" />
          </Grid>
        </>
      )}

      {step === Steps.norstatAddAlias && (
        <>
          <Typography variant="h5" className={classes.gutterBottom2}>
            Skriv inn et navn
          </Typography>
          <Typography variant="body1" className={classes.gutterBottom}>
            Du kan skrive inn ditt fornavn{' '}
          </Typography>
          <form onSubmit={handleAddAliasNorstat}>
            <Grid container justifyContent="space-between">
              <div className={classes.passcodeContainer}>
                <InputLabel shrink htmlFor="fname">
                  Fornavn
                </InputLabel>

                <TextField
                  id="fname"
                  //type="firstName"
                  //name="firstName"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setAlias(e.target.value)}
                  variant="outlined"
                  size="small"
                />
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={!alias.length}
                  className={classes.submitButtonMargin}
                >
                  Neste
                </Button>
              </div>
              <img src={NorstatLogo} width="150px" alt="Norstat logo" />
            </Grid>
          </form>
          <Typography variant="body2"></Typography>
        </>
      )}

      {step === Steps.testIsFull && (
        <>
          <Typography variant="h5" className={classes.gutterBottom}>
            Denne testen er nå avsluttet!
          </Typography>
          <Typography variant="body1">
            Vi har fått mange nok svar på denne testen, men fortvil ikke, det kommer stadig flere tester!
          </Typography>
        </>
      )}

      {step === Steps.noAccess && (
        <>
          <Typography variant="h5" className={classes.gutterBottom}>
            Du har ikke tilgang
          </Typography>
          <Typography variant="body1">
            Det kan virke som om du ikke har tilgang til å ta tester hos oss. Om du tenker dette er feil, ta gjerne
            kontakt med panel@testflow.no
          </Typography>
        </>
      )}

      {step === Steps.testIsExpired && (
        <>
          <Typography variant="h5" className={classes.gutterBottom}>
            Testen er ikke lenger tilgjengelig
          </Typography>
          <Typography variant="body1">
            Denne testen er ikke lenger tilgjengelig, men fortvil ikke, det kommer stadig flere tester.
          </Typography>
        </>
      )}

      <Dialog7
        open={dialog7Open}
        onClose={() => {
          setDialog7Open(false);
        }}
      />
      <Dialog8
        open={dialog8Open}
        onClose={() => {
          setDialog8Open(false);
        }}
      />
      <Dialog9
        open={dialog8Open}
        onClose={() => {
          setDialog8Open(false);
        }}
      />
      <Dialog10
        open={dialog10Open}
        onClose={() => {
          setDialog10Open(false);
        }}
      />
      {/*
      <Dialog11
        open={dialog11Open}
        onClose={() => {
          setDialog11Open(false);
        }}
      />
      */}
    </IntroContainer>
  );
}
