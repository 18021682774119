import React, { PropsWithChildren } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';

interface AboutDialogProps {
  open: boolean;
  onClose(): void;
}

function Dialog8({ open, onClose }: PropsWithChildren<AboutDialogProps>) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="xs">
      <DialogTitle>Personvern</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>
          Your privacy is important to us. It is Testflow AS' policy to respect your privacy and comply with any
          applicable law and regulation regarding any personal information we may collect about you, including across
          our website, https://www.testflow.no, and other sites we own and operate. This policy is effective as of 15
          January 2022 and was last updated on 15 January 2022. ‍<h4>Information We collect</h4>
          identified by itself, it may be possible to combine it with other data to identify individual persons
          personally Information we collect includes both information you knowingly and actively provide us when using
          or participating in any of our services and promotions, and any information automatically sent by your devices
          in the course of accessing our products and services. ‍Log Data When you visit our website, our servers may
          automatically log the standard data provided by your web browser. It may include your device’s Internet
          Protocol (IP) address, your browser type and version, the pages you visit, the time and date of your visit,
          the time spent on each page, other details about your visit, and technical details that occur in conjunction
          with any errors you may encounter. Please be aware that while this information may not be personally
          identifying by itself, it may be possible to combine it with other data to personally identify individual
          persons. ‍<h4>‍Collection and Use of Information</h4>
          ‍‍We may collect personal information from you when you do any of the following on our website:Use a mobile
          device or web browser to access our content. Contact us via email, social media, or on any similar
          technologies when you mention us on social media may collect, hold, use, and disclose information for the
          following purposes, and personal information will not be further processed in a manner that is incompatible
          with these purposes: Please be aware that we may combine information we collect about you with general
          information or research data we receive from other trusted sources. ‍‍
          <h4>Security of Your Personal Information</h4>
          ‍When we collect and process personal information, and while we retain this information, we will protect it
          within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure,
          copying, use, or modification. Although we will do our best to protect the personal information you provide to
          us, we advise that no method of electronic transmission or storage is 100% secure, and no one can guarantee
          absolute data security. We will comply with laws applicable to us in respect of any data breach. You are
          responsible for selecting any password and its overall security strength, ensuring the security of your own
          information within the bounds of our services. ‍‍<h4>How Long We Keep Your Personal Information</h4>
          ‍We keep your personal information only for as long as we need to. This period may depend on what we are using
          your information for, by this privacy policy. If your personal information is no longer required, we will
          delete it or make it anonymous by removing all details that identify you. However, if necessary, we may retain
          your personal information for our compliance with a legal, accounting, or reporting obligation or for
          archiving purposes in the public interest, scientific, or historical research purposes, or statistical
          purposes. ‍‍<h4>Children’s Privacy</h4>
          ‍We do not aim any of our products or services directly at children under the age of 18, and we do not
          knowingly collect personal information about children under 18. ‍‍
          <h4>Disclosure of Personal Information to Third Parties</h4>
          ‍We may disclose personal information to: a parent, subsidiary, or affiliate of our company third party
          service providers for the purpose of enabling them to provide their services, for example, IT service
          providers, data storage, hosting and server providers, advertisers, or analytics platforms or employees,
          contractors, and/or related entities. our existing or potential agents or business partners, sponsors or
          promoters of any competition, sweepstakes, or promotion we run courts, tribunals, regulatory authorities, and
          law enforcement officers, as required by law, in connection with any actual or prospective legal proceedings,
          or to establish, exercise, or defend our provide us with personal information, you understand that we will
          collect, hold, use, and disclose it according to rights third parties, including agents or sub-contractors,
          who assist us in providing information, products, services. Any subprocessors with GDPR related data
          touchpoints are made mandatory to uphold the current GDPR/SHREMS II regulations. ‍
          <h4>‍International Transfers of Personal Information</h4>
          ‍The personal information we collect is stored and/or processed where we or our partners, affiliates, and
          third-party providers maintain facilities. Please be aware that the locations to which we store, process, or
          transfer your personal information may not have the same data protection laws as the country in which you
          initially provided the information. If we transfer your personal information to third parties in other
          countries: (i) we will perform those transfers in accordance with the requirements of applicable law; and (ii)
          we will protect the transferred personal information in accordance with this privacy policy. ‍‍
          <h4>Your Rights and Controlling Your Personal Information</h4>
          ‍You always retain the right to withhold personal information from us, with the understanding that your
          experience of our website may be affected. We will not discriminate against you for exercising any of your
          rights over your personal information. If you do provide us with personal information you understand that we
          will collect, hold, use and disclose it in accordance with this privacy policy. You retain the right to
          request details of any personal information we hold about you.If we receive personal information about you
          from a third party, we will protect it as set out in this privacy policy. If you are a third party providing
          personal information about somebody else, you represent and warrant that you have such person’s consent to
          provide the personal information to us.If you have previously agreed to us using your personal information for
          direct marketing purposes, you may change your mind at any time. We will provide you with the ability to
          unsubscribe from our email-database or opt out of communications. Please be aware we may need to request
          specific information from you to help us confirm your identity.If you believe that any information we hold
          about you is inaccurate, out of date, incomplete, irrelevant, or misleading, please contact us using the
          details provided in this privacy policy. We will take reasonable steps to correct any information found to be
          inaccurate, incomplete, misleading, or out of date.If you believe that we have breached a relevant data
          protection law and wish to make a complaint, please contact us using the details below and provide us with
          full details of the alleged breach. We will promptly investigate your complaint and respond to you, in
          writing, setting out the outcome of our investigation and the steps we will take to deal with your complaint.
          You also have the right to contact a regulatory body or data protection authority in relation to your
          complaint. ‍‍<h4>Use of Cookies</h4>
          ‍We use “cookies” to collect information about you and your activity across our site. A cookie is a small
          piece of data that our website stores on your computer, and accesses each time you visit, so we can understand
          how you use our site. This helps us serve you content based on preferences you have specified. ‍‍
          <h4>Limits of Our Policy</h4>
          ‍Our website may link to external sites that are not operated by us. Please be aware that we have no control
          over the content and policies of those sites, and cannot accept responsibility or liability for their
          respective privacy practices. ‍‍<h4>Changes to This Policy</h4>
          At our discretion, we may change our privacy policy to reflect updates to our business processes, current
          acceptable practices, or legislative or regulatory changes. If we decide to change this privacy policy, we
          will post the changes here at the same link by which you are accessing this privacy policy.If required by law,
          we will get your permission or give you the opportunity to opt in to or opt out of, as applicable, any new
          uses of your personal information. ‍Contact Us ‍For any questions or concerns regarding your privacy, you may
          contact at panel@testflow.no
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Dialog8;
