import React, { useEffect, useCallback, useState } from 'react';

import { Typography, Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
//import LocalVideoPreview from './LocalVideoPreview/LocalVideoPreview';
//import SettingsMenu from './SettingsMenu/SettingsMenu';
import { Steps } from '../PreJoinScreens';
//import ToggleAudioButton from '../../Buttons/ToggleAudioButton/ToggleAudioButton';
//import ToggleVideoButton from '../../Buttons/ToggleVideoButton/ToggleVideoButton';
import { useAppState } from '../../../state';
//import useChatContext from '../../../hooks/useChatContext/useChatContext';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

interface DeviceSelectionScreenProps {
  name: string;
  roomName: string;
  setStep: (step: Steps) => void;
}

export default function DeviceSelectionScreen({ name, roomName, setStep }: DeviceSelectionScreenProps) {
  const { getToken, isFetching } = useAppState();
  //const { connect: chatConnect } = useChatContext();
  const { connect: videoConnect, isConnecting } = useVideoContext();

  const [tryJoin, tryJoined] = useState(false);

  const handleJoin = useCallback(() => {
    tryJoined(true);
    getToken(name, roomName).then(({ token }) => {
      videoConnect(token);
      //process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true' && chatConnect(token);
    });
  }, [name, roomName, videoConnect, getToken]);

  useEffect(() => {
    if (!tryJoin) {
      handleJoin();
    }
  }, [handleJoin, tryJoin]);

  if (isFetching || isConnecting) {
    return (
      <Grid container justifyContent="center" alignItems="center" direction="column" style={{ height: '100%' }}>
        <div>
          <CircularProgress variant="indeterminate" />
        </div>
        <div>
          <Typography variant="body2" style={{ fontWeight: 'bold', fontSize: '16px' }}>
            Forhåndsviser test...
          </Typography>
        </div>
      </Grid>
    );
  }

  return (
    <Grid container justifyContent="center" alignItems="center" direction="column" style={{ height: '100%' }}>
      <div>
        <CircularProgress variant="indeterminate" />
      </div>
      <div>
        <Typography variant="body2" style={{ fontWeight: 'bold', fontSize: '16px' }}>
          Noe gikk feil, vennligst kontakt support
        </Typography>
      </div>
    </Grid>
  );
}
