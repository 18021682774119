import { useCallback, useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import sanityClient from '../../sanity';
//import sanityTesterClient from '../../sanityTesterClient';
//import useCaseDataState from '../../hooks/useCaseDataState/useCaseDataState';
import { nanoid } from 'nanoid';
import { User, defaultUser } from '../../types';
import { useApiGet } from '../../hooks/useApiGet/useApiHook';

const endpoint = process.env.REACT_APP_TOKEN_ENDPOINT || '/token';

export function getPasscode() {
  const match = window.location.search.match(/passcode=(.*)&?/);
  const passcode = match ? match[1] : window.sessionStorage.getItem('passcode');
  return passcode;
}

export function fetchToken(
  name: string,
  room: string,
  passcode: string,
  create_room = true,
  create_conversation = process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true'
) {
  return fetch(endpoint, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      identity: name, // REMEMBER FOR LATER THAN THIS HAS ANOTHER WORDING: BEOFRE IT WAS user_identity and room_name
      room: room,
      user_identity: name, // THIS CAN MAYBE BE FIXED. JUST USED FOR RUNNING LOCAL TOEN
      room_name: room, // THIS CAN MAYBE BE FIXED. JUST USED FOR RUNNING LOCAL TOEN
      passcode,
      create_room,
      create_conversation,
    }),
  });
}

export function verifyPasscode(passcode: string) {
  return fetchToken('temp-name', 'temp-room', passcode, false /* create_room */, false /* create_conversation */).then(
    async res => {
      const jsonResponse = await res.json();
      if (res.status === 401) {
        return { isValid: false, error: jsonResponse.error?.message };
      }

      if (res.ok && jsonResponse.token) {
        return { isValid: true };
      }
    }
  );
}

export function getErrorMessage(message: string) {
  switch (message) {
    case 'passcode incorrect':
      return 'Passcode is incorrect';
    case 'passcode expired':
      return 'Passcode has expired';
    default:
      return message;
  }
}

export default function usePasscodeAuth() {
  const history = useHistory();

  const userDataEndpoint = process.env.REACT_APP_GET_USERDATA || 'missing endpoint'; // Ensure the endpoint is correct
  const [user, setUser] = useState<User | null>(defaultUser);
  const [isAuthReady, setIsAuthReady] = useState(false);

  const getToken = useCallback(
    (name: string, room: string) => {
      return fetchToken(name, room, user!.passcode)
        .then(async res => {
          if (res.ok) {
            return res;
          }
          const json = await res.json();
          const errorMessage = getErrorMessage(json.error?.message || res.statusText);
          throw Error(errorMessage);
        })
        .then(res => res.json());
    },
    [user]
  );

  const updateRecordingRules = useCallback(
    async (room_sid, rules) => {
      const endpointRecording = process.env.REACT_APP_RECORDING_ENDPOINT || '/recordingrules';

      return fetch(endpointRecording, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ room_sid, rules, passcode: user?.passcode }),
        method: 'POST',
      }).then(async res => {
        const jsonResponse = await res.json();

        if (!res.ok) {
          const error = new Error(jsonResponse.error?.message || 'There was an error updating recording rules');
          error.code = jsonResponse.error?.code;
          return Promise.reject(error);
        }

        return jsonResponse;
      });
    },
    [user]
  );

  const passcode = getPasscode();

  // Use useMemo to extract fields and ensure it only recalculates when passcode changes
  const userId = useMemo(() => {
    if (passcode) {
      const fields = passcode.split('-');
      return fields[1];
    }
    return null;
  }, [passcode]);

  // Ensure the API hook is only called when userId is valid
  const { data: apiData } = useApiGet(userId ? `${userDataEndpoint}${userId}` : '');

  useEffect(() => {
    if (!passcode) {
      setIsAuthReady(true);
      return;
    }

    verifyPasscode(passcode)
      .then(verification => {
        if (verification?.isValid) {
          //console.log('Passcode is valid');
          if (apiData) {
            const userdata = apiData.body.data;

            const userData: User = {
              displayName: userdata.firstName || 'Unknown',
              userPreferences: {
                device: userdata.userDevice || 'ios',
                language: userdata.userLanguage || 'nb-NO',
              },
              meta: {
                type: '', // Set this from userdata if available
                origin: '', // Set this from userdata if available
              },
              photoURL: undefined, // Set this from userdata if available
              passcode: passcode,
            };
            setUser(userData);

            // Save passcode to session storage and update history
            window.sessionStorage.setItem('passcode', passcode);
            history.replace(window.location.pathname);
          }
        }
      })
      .then(() => setIsAuthReady(true));
  }, [history, passcode, apiData]);

  // EXPERIMENT FOR NOW
  const getTestCaseData = useCallback(async slug => {
    //console.log(JSON.stringify(slug))

    const baseEndpoint = process.env.REACT_APP_GET_TESTCASE_ENDPOINT;
    const testcaseEndpoint = baseEndpoint ? `${baseEndpoint}${slug}` : 'missing endpoint';

    return fetch(testcaseEndpoint, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8', // TBD: TMP JUST TO BE ABLE TO FETCH DATA DUE CORS ERRORS
      },
      body: JSON.stringify({ slug }),
      method: 'POST',
    }).then(async res => {
      const jsonResponse = await res.json();

      if (!res.ok) {
        const error = new Error(jsonResponse.error?.message || 'There was an error getting testcase data');
        error.code = jsonResponse.error?.code;
        return Promise.reject(error);
      }
      //console.log('TestCase data is: ' + JSON.stringify(jsonResponse));
      return jsonResponse;
    });
  }, []);

  // THIS WRITES IN DATA TO SANITY AFTER THE TEST IS COMPLETED.
  // THIS AND THE NEXT FUNCTION SHOULD MAY BE SPLITTED INTO OWN FILE?

  const startTest = useCallback(async (room_name, room_sid) => {
    var fields = room_name.split(' – '); // // REFERENCE TO PREJOINSCREENS AND SETROOMNAME FUNCTION
    //console.log("TESTER ID FROM STATE IS" + tester_id)

    //console.log('Starting test, ROOM SID IS: ' + room_sid);

    if (fields[1] !== 'Anonym Tester') {
      // This is a temporary solution while use teststudio for preview for admins
      try {
        sanityClient.createIfNotExists({
          _id: room_sid,
          _type: 'testResult',
          testerName: fields[1],
          testCaseSlug: fields[0],
          roomId: room_sid,
          status: 'new',
          testerEmail: fields[4], // CHECK WHAT HAPPENS IF THIS DOES NOT EXIST
          testerId: fields[2],
          source: fields[3], // CHECK WHAT HAPPENS IF THIS DOES NOT EXIST
        });
      } catch (err) {
        console.error(err);
      }
    }
  }, []);

  const endTest = useCallback(async (room_name, room_sid, case_id, redirectionSuccess) => {
    var fields = room_name.split(' – '); // // REFERENCE TO PREJOINSCREENS AND SETROOMNAME FUNCTION

    //console.log('Ending test, ROOM SID IS: ' + room_sid);

    if (fields[1] !== 'Anonym Tester') {
      try {
        sanityClient
          .patch(room_sid)
          .set({ status: 'recordingComplete' })
          .commit()
          .then(() => {
            if (fields[3] === 'norstat') {
              if (isValidUrl(redirectionSuccess)) {
                window.location.href = redirectionSuccess;
              } else {
                window.location.href = 'https://webs.norstatsurveys.com/z/Complete';
              }
            }
          });
      } catch (err) {
        console.error(err);
      }
      // ADD TO TESTCASE ARRAY
      try {
        sanityClient
          .patch(case_id)
          .setIfMissing({ testResults: [] })
          .append('testResults', [{ _key: nanoid(), _ref: room_sid, _type: 'reference' }])
          .commit()
          .then(() => {
            if (fields[3] === 'norstat') {
              if (isValidUrl(redirectionSuccess)) {
                window.location.href = redirectionSuccess;
              } else {
                window.location.href = 'https://webs.norstatsurveys.com/z/Complete';
              }
            }
          });
      } catch (err) {
        console.error(err);
      }
    }
  }, []);

  const signIn = useCallback((inputPasscode: string) => {
    //const displayName = 'hansemann';

    // TODO: Think this is not in use? Should be removed..?

    return verifyPasscode(inputPasscode).then(verification => {
      if (verification?.isValid) {
        //alert("2")
        //console.log('Signing in...');

        setUser({ inputPasscode } as any);

        //setUser({ displayName } as any); // EXPRIMENTAL: FORTSETT HER I MORGEN:::::
        window.sessionStorage.setItem('passcode', inputPasscode);
      } else {
        throw new Error(getErrorMessage(verification?.error));
      }
    });
  }, []);

  function isValidUrl(urlString: string): boolean {
    try {
      new URL(urlString);
      return true;
    } catch (e) {
      return false;
    }
  }

  const signOut = useCallback(redirectionSuccess => {
    setUser(null);
    window.sessionStorage.removeItem('passcode');

    if (redirectionSuccess && isValidUrl(redirectionSuccess)) {
      window.location.href = redirectionSuccess;
    }

    return Promise.resolve();
  }, []);

  return { user, isAuthReady, getToken, signIn, signOut, updateRecordingRules, endTest, startTest, getTestCaseData };
}
