import React, { PropsWithChildren } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';

interface AboutDialogProps {
  open: boolean;
  onClose(): void;
}

function Dialog6({ open, onClose }: PropsWithChildren<AboutDialogProps>) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="xs">
      <DialogTitle>Om Testflow</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>
          <p>
            Testflow er et norsk selskap som kobler norske selskaper til forbrukere via brukertesting. Vår plattform
            gjør det enkelt å teste alt fra nettsider, apper, design, innhold, tjenester og produkter. Ved å observere
            og lytte til brukertester reduseres potensielle feil, misforståelser og risiko. Testing gir verdifull
            innsikt og tilbakemeldinger som brukes til å lage skikkelig bra digitale produkter og tjenester.{' '}
          </p>
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Dialog6;
