import React, { PropsWithChildren } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';

interface AboutDialogProps {
  open: boolean;
  onClose(): void;
}

function Dialog7({ open, onClose }: PropsWithChildren<AboutDialogProps>) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="xs">
      <DialogTitle>Vilkår for testere</DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>
          <p>
            Disse vilkårene beskriver avtalen mellom deg som oppretter en konto for å utføre tester (heretter kalt
            “tester”, “deg”) og Testflow AS (heretter kalt “Testflow”). Vilkårene beskriver hvilke rettigheter og
            plikter som gjelder mellom deg og Testflow.
          </p>
          <p>
            Du har godkjent og samtykket til vilkårene når du aktivt merker av i avkrysningsboksen for at du godkjenner
            vilkårene.{' '}
          </p>
          <h4>1. Hvem kan være tester </h4>
          Du må være over 18 år og bosatt i Norge for å utføre en test.
          <h4>2. Opptak og bruk av opptak</h4>
          <p>
            3.1 Du forstår og godtar at hver test du tar er tatt opp i et opptak og at opptaket inkluderer video fra
            ditt webkamera, lydopptak av stemmen din og opptak av skjermen din.{' '}
          </p>
          <p>
            3.2 Du forstår og godtar videre at Testflow vil gi en kopi av opptaket til kunden som har opprettet testen,
            og at opptaket kan sees og brukes av kunde til sitt arbeid og vises internt i sin organisasjon. Testflow og
            kunde av Testflow vil også kunne se og bruke videoene internt.
          </p>
          <h4>4. Taushetsplikt</h4>
          <p>
            Du godtar full konfidensialitet og taushetsplikt av alt du tester. Som tester vil du kunne teste prototyper,
            konsepter, tjenester som ikke er tilgjengelig på markedet, og du godtar å ikke dele informasjon som du
            tilegner deg gjennom testing hos Testflow.{' '}
          </p>
          <p>
            Du skal ikke gi informasjon til en tredjepart eller snakke om det du har testet i Testflow. Dette inkluderer
            innhold i testen, informasjon om kunder og informasjon om hva som testes. Du kan ikke kopiere eller
            registrere noen av resultatene fra brukertestingen din. Du kan ikke kopiere eller ta skjermbilder/videoer av
            testene, og/eller avsløre denne informasjonen til andre testere eller tredjeparter, verken personlig, via
            Internett eller noen annen form for kommunikasjon. Du skal ikke lenger bruke det du har testet, etter testen
            er fullført av deg.
          </p>
          <p>
            Hvis tjenestene for brukertesting gir deg tilgang til et passordbeskyttet nettsted eller et ikke-offentlig
            nettsted, skal du ikke bruke dette, etter testen er fullført.{' '}
          </p>
          <h4>5. Brukertesting</h4>
          <h4>5.1 Før du starter en test</h4>
          <p>Sørg for å lese og følge alle oppgaver/spørsmål nøye</p>
          <p>
            Når du svarer på spørsmål eller følger instruksjoner, prøv å ikke avvike for langt fra spørsmålet/oppgaven.
            Både når du leser oppgavene og utfører testen, snakk tydelig og kommenter høyt og del tankene du måtte ha
            under brukeropplevelsen gjennom testen. Husk at du blir tatt opp via webkamera så ikke opptre upassende, men
            vær deg selv
          </p>
          <p>
            Sørg for at du har de tekniske minimumskravene for å utføre testen. a) Bredbånd Internett-tilkobling eller
            tilsvarende. b) Et brukende webkamera og mikrofon
          </p>
          <p>
            Vennligst sjekk at mikrofonen og webkameraet ditt fungerer som det skal for å sikre at det ikke vil være
            forstyrrelser med lyd-, bilde- eller skjermopptak under testen. Hvis du ikke overholder de ovennevnte
            kravene, kan innsendingen din bli avvist og du vil ikke bli betalt for innsatsen din.
          </p>
          <h4>5.2 Gjennomføring av testen</h4>
          <p>
            En test kan ha en varighet på 5, 10 eller 20 minutter. Hold deg så langt det lar seg gjøre, i nærheten av
            lengden på testen. Ved gjennomføring av testen må du sørge for at du er alene, ikke har noen sensitive data
            synlige på skjermen du deler, og ikke under noen omstendigheter avsløre fullt navn på tredjeparter (som
            familiemedlemmer osv.) eller annen sensitiv personlig informasjon. Testflow godtgjør bare for godkjente
            tester og dersom vilkårene på denne siden er oppfylt.
          </p>
          <h4>6: Godtgjørelse</h4>
          <p>Godtgjørelse eller honorar etter godkjent test gjøres etter avtale.</p>
          <h4>6.1 Godtgjørelse for godkjente tester</h4>
          <p>6.2 Krav til godkjent test</p>
          <p>En test blir godkjent når man har oppfylt disse kravene:</p>
          <ul>
            <li>At vi kan se tydelig video av deg som tester</li>
            <li>At vi hører en tydelig stemme uten for mye støy i bakgrunnen</li>
            <li>At du snakker høyt under testen</li>
            <li>At vi ser skjermdeling som viser Testflow og eventuelle linker som åpnes</li>
            <li>At du svarer på spørsmålene du blir stilt og utfører oppgavene du får</li>
            <li>At du holder deg innen rimelig tidslengde basert på test-type 5 min, 10 min og 20 min. </li>
          </ul>
          <h4>7. Konkurranse</h4>
          <p>
            Du samtykker i å ikke kontakte kunder som har kjøpt testtjenester gjennom nettstedet vårt eller gjennom
            informasjon hentet fra nettstedet vårt med den hensikt å tilby dine egne tjenester direkte til kunden som en
            erstatning for våre tjenester.
          </p>
          <h4>8. Tilbaketrekke en test</h4>
          <p>
            Du kan etter endt test forespørre å få din test tilbaketrukket og slettet fra våre systemer. Du kan gjøre
            dette ved å sende mail til panel@testflow.no med referansenummer og dato for test.
          </p>
          <p>
            For spørsmål angående disse vilkårene, vennligst kontakt panel@testflow.no eller vår chat på www.testflow.no
          </p>
          <p>
            Lykke til som tester,
            <br />
            Hilsen Testflow
          </p>
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Dialog7;
