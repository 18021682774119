import React, { SVGProps } from 'react';

export default function TestflowLogo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg fill="#FFFFFF" {...props} width="154" height="24" viewBox="0 0 154 24" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3680_9150)">
        <path
          d="M20.848 2.7251V11.9943H15.4374C14.6265 11.9943 13.8235 12.1542 13.0743 12.4648C12.3252 12.7754 11.6446 13.2306 11.0713 13.8045C10.4981 14.3784 10.0435 15.0596 9.73358 15.8093C9.42362 16.559 9.26435 17.3625 9.26489 18.1738V23.5829H0V18.6222C0 14.406 1.67408 10.3625 4.65397 7.38125C7.63385 4.39997 11.6755 2.7251 15.8897 2.7251H20.848Z"
          fill="white"
        />
        <path
          d="M20.844 19.8898C20.844 17.8502 19.1914 16.1968 17.1527 16.1968C15.1141 16.1968 13.4614 17.8502 13.4614 19.8898C13.4614 21.9294 15.1141 23.5828 17.1527 23.5828C19.1914 23.5828 20.844 21.9294 20.844 19.8898Z"
          fill="white"
        />
        <path
          d="M35.9555 19.9345C35.416 19.9505 34.8798 19.8446 34.3869 19.6247C33.9846 19.4468 33.6543 19.1379 33.4498 18.7483C33.2407 18.2912 33.1416 17.7914 33.1605 17.289V10.1598H37.0311V6.56464H33.1605V2.75342H29.0863V4.01295C29.1113 4.36008 29.0645 4.70864 28.9489 5.03687C28.8332 5.3651 28.6511 5.66594 28.414 5.9206C27.887 6.37639 27.2025 6.60758 26.5072 6.56464H26.2017V10.1598H29.0863V17.5418C29.0863 19.4059 29.6091 20.8598 30.6548 21.9033C31.7006 22.9468 33.1646 23.4699 35.0469 23.4726C35.3566 23.4726 35.6988 23.4726 36.0777 23.4278C36.4566 23.3829 36.7785 23.3585 37.0433 23.3177V19.8448L36.477 19.9059C36.3037 19.9241 36.1297 19.9336 35.9555 19.9345Z"
          fill="white"
        />
        <path
          d="M52.7415 8.62298C52.0371 7.85102 51.1721 7.24298 50.2073 6.8417C49.1066 6.39071 47.9253 6.17014 46.7361 6.19359C45.2513 6.17424 43.7898 6.56342 42.511 7.31861C41.2559 8.06187 40.222 9.12715 39.5164 10.4043C38.7649 11.7774 38.3843 13.3228 38.4123 14.8881C38.3937 16.4306 38.7636 17.9529 39.4879 19.3148C40.182 20.6256 41.222 21.721 42.4947 22.482C43.8636 23.2831 45.4275 23.6895 47.0131 23.6559C48.0893 23.6669 49.1591 23.4903 50.1747 23.1341C51.0948 22.8109 51.9451 22.3157 52.6804 21.6749C53.3471 21.1031 53.868 20.3807 54.2001 19.5675L50.9407 17.9696C50.5546 18.6487 49.9893 19.2086 49.3068 19.5882C48.6242 19.9677 47.8505 20.1523 47.0702 20.1219C46.2092 20.1382 45.3613 19.9092 44.6256 19.4615C43.9231 19.024 43.367 18.3867 43.0285 17.6313C42.809 17.145 42.6713 16.6258 42.621 16.0946H54.6442C54.7144 15.8267 54.7661 15.5543 54.799 15.2794C54.8397 14.974 54.8601 14.6662 54.8602 14.3582C54.8698 13.2828 54.682 12.2148 54.306 11.2073C53.95 10.2558 53.4194 9.37936 52.7415 8.62298V8.62298ZM44.5115 10.176C45.1783 9.74553 45.959 9.52542 46.7523 9.5442C47.5494 9.5287 48.3318 9.75927 48.9932 10.2045C49.6219 10.6236 50.1013 11.2317 50.3621 11.941C50.4883 12.2812 50.5625 12.6385 50.5822 13.0008H42.7473C42.8099 12.6634 42.9054 12.333 43.0325 12.0144C43.3281 11.2659 43.8438 10.6248 44.5115 10.176V10.176Z"
          fill="white"
        />
        <path
          d="M61.383 9.96019C61.8519 9.64 62.4134 9.4838 62.9801 9.51589C63.7921 9.50278 64.5895 9.73273 65.2699 10.1762C65.9266 10.6068 66.4279 11.2369 66.6999 11.9738L69.7679 10.5594C69.3022 9.22001 68.392 8.08066 67.1888 7.33106C65.9332 6.56244 64.4845 6.16794 63.0127 6.19381C61.908 6.17233 60.8121 6.39357 59.8022 6.84192C58.9261 7.22442 58.172 7.84066 57.6225 8.6232C57.0853 9.42161 56.8105 10.3677 56.8361 11.3298C56.8186 12.4261 57.201 13.4912 57.9117 14.3258C58.7654 15.2584 59.8774 15.9155 61.1059 16.213L63.8398 16.9508C64.1813 17.0444 64.5118 17.1742 64.8258 17.3381C65.0894 17.4741 65.3235 17.6611 65.5143 17.8883C65.694 18.1211 65.7863 18.4097 65.7751 18.7036C65.7815 18.9535 65.7279 19.2013 65.6187 19.4261C65.5095 19.651 65.348 19.8463 65.1476 19.9957C64.6401 20.3484 64.0289 20.5206 63.412 20.4849C62.4965 20.4934 61.6024 20.2077 60.8615 19.6696C60.0827 19.1009 59.4592 18.3454 59.0484 17.4726L56.009 18.9155C56.5011 20.3487 57.4707 21.5691 58.7551 22.3721C60.1485 23.2466 61.7676 23.693 63.412 23.6561C64.5456 23.6823 65.6715 23.4626 66.7121 23.0121C67.5961 22.6201 68.3597 21.9996 68.9245 21.2145C69.4702 20.4356 69.7555 19.5038 69.7393 18.5528C69.768 17.4451 69.3714 16.3686 68.6311 15.5445C67.7571 14.6399 66.6526 13.9915 65.4369 13.6695L62.7642 12.8991C62.4128 12.7875 62.0697 12.6513 61.7375 12.4915C61.4668 12.3704 61.2292 12.1859 61.0449 11.9534C60.8748 11.7165 60.7888 11.4296 60.8004 11.1382C60.7956 10.9094 60.8461 10.6829 60.9476 10.4778C61.049 10.2728 61.1984 10.0952 61.383 9.96019V9.96019Z"
          fill="white"
        />
        <path
          d="M80.4831 19.9345C79.9449 19.9505 79.4101 19.8446 78.9186 19.6247C78.5155 19.4479 78.1849 19.1387 77.9815 18.7483C77.7689 18.2922 77.6684 17.7919 77.6882 17.289V10.1598H81.5587V6.56464H77.6882V2.75342H73.6342V4.01295C73.6597 4.36045 73.6128 4.70947 73.4964 5.03785C73.38 5.36624 73.1966 5.66684 72.9579 5.9206C72.4328 6.37692 71.7494 6.60826 71.0553 6.56464H70.7456V10.1598H73.6342V17.5418C73.6342 19.4059 74.1571 20.8598 75.2029 21.9033C76.2486 22.9468 77.7126 23.4699 79.5949 23.4726C79.9005 23.4726 80.2427 23.4726 80.6216 23.4278C81.0005 23.3829 81.3224 23.3585 81.5913 23.3177V19.8448L81.0209 19.9059C80.8423 19.9249 80.6627 19.9344 80.4831 19.9345V19.9345Z"
          fill="white"
        />
        <path
          d="M96.6621 6.5647H90.1881V6.04295C90.1611 5.69347 90.2151 5.34247 90.3458 5.01726C90.4766 4.69205 90.6805 4.40139 90.9419 4.16791C91.5399 3.72644 92.2737 3.50865 93.0157 3.55241C93.1583 3.55241 93.3171 3.55241 93.4923 3.55241C93.6828 3.56434 93.872 3.59023 94.0587 3.62986V0.156964C93.815 0.106893 93.5689 0.0701447 93.3212 0.046899C93.0157 0.0183658 92.7386 0.00206707 92.5064 0.00206707C91.3453 -0.0251208 90.1936 0.216208 89.141 0.707249C88.2172 1.15105 87.4468 1.86073 86.9287 2.74533C86.3815 3.74292 86.1114 4.86891 86.1464 6.00627V6.52802H83.217V10.1232H86.1342V23.2485H90.2085V10.1232H96.6621V23.2485H100.688V0.0142951H96.6621V6.5647Z"
          fill="white"
        />
        <path
          d="M116.219 7.31865C114.841 6.57867 113.301 6.19141 111.737 6.19141C110.173 6.19141 108.633 6.57867 107.255 7.31865C105.235 8.50052 103.766 10.4358 103.17 12.7001C102.574 14.9643 102.901 17.3725 104.077 19.3963C104.856 20.6991 105.963 21.7744 107.288 22.5146C108.651 23.2606 110.179 23.6516 111.733 23.6516C113.286 23.6516 114.815 23.2606 116.178 22.5146C117.496 21.7704 118.599 20.6958 119.376 19.3963C119.981 18.401 120.379 17.2942 120.547 16.1417C120.715 14.9893 120.65 13.8148 120.356 12.688C120.061 11.5613 119.543 10.5053 118.832 9.58285C118.122 8.66043 117.233 7.89044 116.219 7.31865V7.31865ZM115.742 17.5417C115.362 18.2669 114.801 18.8808 114.112 19.323C113.395 19.7595 112.568 19.9829 111.729 19.967C110.881 19.9802 110.046 19.7572 109.317 19.323C108.615 18.8892 108.05 18.2661 107.687 17.5254C107.286 16.708 107.086 15.8066 107.104 14.8962C107.087 14.0089 107.293 13.1315 107.703 12.3446C108.084 11.6246 108.646 11.0163 109.333 10.5796C110.058 10.1474 110.885 9.91916 111.729 9.91916C112.572 9.91916 113.4 10.1474 114.124 10.5796C114.814 11.0206 115.376 11.6348 115.754 12.3609C116.164 13.1539 116.37 14.0364 116.353 14.9289C116.371 15.8372 116.161 16.7355 115.742 17.5417V17.5417Z"
          fill="white"
        />
        <path
          d="M143.105 6.56494L139.617 17.0855L135.918 6.56494H132.324L128.625 17.0855L125.137 6.56494H120.896L126.64 23.2854H130.205L134.133 11.9944L138.008 23.2854H141.601L147.374 6.56494H143.105Z"
          fill="white"
        />
        <path d="M146.882 20.6927H147.863V23.4523H148.564V20.6927H149.55V20.0283H146.882V20.6927Z" fill="white" />
        <path
          d="M152.589 20.0283L151.718 21.3449L150.838 20.0283H150.035V23.4523H150.732V20.9985L151.681 22.4373H151.746L152.699 20.9985V23.4523H153.396V20.0283H152.589Z"
          fill="white"
        />
      </g>
    </svg>
  );
}
